import { defineMessages } from 'react-intl';

export default defineMessages({
    selectModelType: {
        id: 'SelectModelTypeDialog.selectModelType',
        defaultMessage: 'Выберите тип модели',
    },
    cancel: {
        id: 'SelectModelTypeDialog.cancel',
        defaultMessage: 'Отмена',
    },
    create: {
        id: 'SelectModelTypeDialog.create',
        defaultMessage: 'Создать',
    },
    defaultModel: {
        id: 'SelectModelTypeDialog.defaultModel',
        defaultMessage: 'Стандартная модель',
    },
    kanbanBoard: {
        id: 'SelectModelTypeDialog.kanbanBoard',
        defaultMessage: 'Канбан-доска',
    },
    matrix: {
        id: 'SelectModelTypeDialog.matrix',
        defaultMessage: 'Матрица',
    },
    report: {
        id: 'SelectModelTypeDialog.report',
        defaultMessage: 'Справочник',
    },
});

import { TSettingsTabsProps } from '@/modules/UIKit/components/SettingsTabs/SettingsTabs.types';
import classNames from 'classnames';
import React, { KeyboardEventHandler, useEffect, useMemo, useRef, useState } from 'react';
import { switchFocusElement } from '@/utils/elementFocus.utils';

import theme from './SettingsTabs.scss';

export const SettingsTabs = <T extends string | number>({ defaultKey, items, focusOnMount }: TSettingsTabsProps<T>) => {
    const [activeKey, setActiveKey] = useState(defaultKey);
    const tabListRef = useRef<HTMLDivElement>(null);
    const tabPanelRef = useRef<HTMLDivElement>(null);
    const rootNodeRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        focusOnMount && rootNodeRef.current?.focus();
    }, []);

    const onKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
        const index = items.findIndex((item) => item.key === activeKey);

        switch (e.key) {
            case 'ArrowDown':
                if (index < items.length - 1) {
                    setActiveKey(items[index + 1].key);
                }
                e.preventDefault();
                break;

            case 'ArrowUp':
                if (index > 0) {
                    setActiveKey(items[index - 1].key);
                }
                e.preventDefault();
                break;

            case 'Tab':
                if (tabPanelRef.current && !e.shiftKey) {
                    switchFocusElement(tabPanelRef.current);
                    e.stopPropagation();
                }
                e.preventDefault();
                break;
        }
    };

    const onKeyDownTabPanel: KeyboardEventHandler<HTMLDivElement> = (e) => {
        if (tabPanelRef.current) {
            switch (e.key) {
                case 'Tab':
                    if (tabPanelRef.current && e.shiftKey) {
                        tabListRef.current?.focus();
                        e.preventDefault();
                        e.stopPropagation();
                    }
                    break;
            }
        }
    };

    const onClickHandlers = useMemo(() => items.map((item) => () => setActiveKey(item.key)), [items]);

    return (
        <div aria-selected="true" tabIndex={0} ref={rootNodeRef} className={theme.root}>
            <div
                ref={tabListRef}
                onKeyDown={onKeyDown}
                className={theme.tabList}
                role="tablist"
                aria-selected="true"
                tabIndex={0}
            >
                {items.map((item, index) => (
                    <button
                        data-node-key={item.key}
                        role="tab"
                        onClick={onClickHandlers[index]}
                        className={classNames(theme.tab, { [theme.tabActive]: item.key === activeKey })}
                    >
                        {item.label}
                    </button>
                ))}
            </div>
            <div
                className={theme.tabPanel}
                onKeyDown={onKeyDownTabPanel}
                aria-selected="true"
                tabIndex={0}
                ref={tabPanelRef}
            >
                {items.map((item) => (
                    <div
                        tabIndex={item.key !== activeKey ? -1 : undefined}
                        className={classNames(theme.tabContent, { [theme.tabContentHidden]: item.key !== activeKey })}
                        role="tabpanel"
                    >
                        {item.children}
                    </div>
                ))}
            </div>
        </div>
    );
};

export enum DialogType {
    SERVER_EDIT = 'SERVER_EDIT',
    AUTHORIZATION = 'AUTHORIZATION',
    DATABASE_CREATE = 'DATABASE_CREATE',
    PROPERTIES_DIALOG = 'PROPERTIES_DIALOG',
    PRICIPAL_ATTRIBUTE_DIALOG = 'PRICIPAL_ATTRIBUTE_DIALOG',
    DECOMPOSITIONS_LIST_DIALOG = 'DECOMPOSITIONS_LIST_DIALOG',
    OBJECT_DECOMPOSITION_CREATE = 'OBJECT_DECOMPOSITION_CREATE',
    EDIT_SYMBOL_FLOATING_ATTRIBUTES_DIALOG = 'EDIT_SYMBOL_FLOATING_ATTRIBUTES_DIALOG',
    EDIT_EDGE_TYPE_FLOATING_ATTRIBUTES_DIALOG = 'EDIT_EDGE_TYPE_FLOATING_ATTRIBUTES_DIALOG',
    OBJECT_DEFINITION_CREATE = 'OBJECT_DEFINITION_CREATE',
    EDGE_MANAGEMENT_DIALOG = 'EDGE_MANAGEMENT_DIALOG',
    ATTRIBUTES_DIALOG = 'ATTRIBUTES_DIALOG',
    FOLDER_DIALOG = 'FOLDER_DIALOG',
    FILE_FOLDER_DIALOG = 'FILE_FOLDER_DIALOG',
    SIMULATION_DIALOG = 'SIMULATION_DIALOG ',
    MODEL_DIALOG = 'MODEL_DIALOG',
    CLONE_MODEL_DIALOG = 'CLONE_MODEL_DIALOG',
    EXPORT_NOTATION_DIALOG = 'EXPORT_NOTATION_DIALOG',
    INSTANCE_PERMISSIONS_DIALOG = 'INSTANCE_PERMISSIONS_DIALOG',
    CHOOSE_PRINCIPAL_DIALOG = 'CHOOSE_PRINCIPAL_DIALOG',
    REPOSITORY_PERMISSIONS_DIALOG = 'REPOSITORY_PERMISSIONS_DIALOG',
    CRITERIA_DIALOG = 'CRITERIA_DIALOG',
    PSD_TABLE_DELETE_CONFIRMATION_DIALOG = 'PSD_TABLE_DELETE_CONFIRMATION_DIALOG',
    CONFLICT_RESOLUTION_DIALOG = 'CONFLICT_RESOLUTION_DIALOG',
    IMPORT_PRESET_DIALOG = 'IMPORT_PRESET_DIALOG',
    NEW_REPOSITORY_NAME_DIALOG = 'NEW_REPOSITORY_NAME_DIALOG',
    REPLACE_CONFIRMATION_DIALOG = 'REPLACE_CONFIRMATION_DIALOG',
    SCRIPT_EXECUTE_DIALOG = 'SCRIPT_EXECUTE_DIALOG',
    SCRIPT_CREATE_DIALOG = 'SCRIPT_CREATE_DIALOG',
    SCRIPT_UPLOAD_PARAM_FILE_DIALOG = 'SCRIPT_UPLOAD_PARAM_FILE_DIALOG',
    DIAGRAM_EXECUTE_SCRIPT_DIALOG = 'DIAGRAM_EXECUTE_SCRIPT_DIALOG',
    EVENT_SCRIPT_DIALOG = 'EVENT_SCRIPT_DIALOG',
    CHOOSE_SYMBOL_FOR_DRAG_DIALOG = 'CHOOSE_SYMBOL_FOR_DRAG_DIALOG',
    WIKI_CREATE_DIALOG = 'WIKI_CREATE_DIALOG',
    LINK_DIALOG = 'LINK_DIALOG',
    TREE_ITEM_SELECT_DIALOG = 'TREE_ITEM_SELECT_DIALOG',
    IMAGE_EDIT_DIALOG = 'IMAGE_EDIT_DIALOG',
    DELETE_NODE_DIALOG = 'DELETE_NODE_DIALOG',
    DELETE_COMMENT_DIALOG = 'DELETE_COMMENT_DIALOG',
    MATRIX_CREATE_DIALOG = 'MATRIX_CREATE_DIALOG',
    MATRIX_SETTINGS_DIALOG = 'MATRIX_SETTINGS_DIALOG',
    MATRIX_CELL_SYMBOLS_SETTINGS_DIALOG = 'MATRIX_CELL_SYMBOLS_SETTINGS_DIALOG',
    MATRIX_CREATE_DEFINITION_DIALOG = 'MATRIX_CREATE_DEFINITION_DIALOG',
    MATRIX_EDGES_LIST_DIALOG = 'MATRIX_EDGES_LIST_DIALOG',
    IMPORT_UPLOAD_DIALOG = 'IMPORT_UPLOAD_DIALOG',
    FILE_UPLOAD_DIALOG = 'FILE_UPLOAD_DIALOG',
    ABOUT_APP_DIALOG = 'ABOUT_APP_DIALOG',
    MODEL_LINK_DIALOG = 'MODEL_LINK_DIALOG',
    COPY_ITEM_DIALOG = 'COPY_ITEM_DIALOG',
    UPLOAD_LICENSE_DIALOG = 'UPLOAD_LICENSE_DIALOG',
    UPLOAD_PRESET_DIALOG = 'UPLOAD_PRESET_DIALOG',
    UPLOAD_SYMBOL_SVG_DIALOG = 'UPLOAD_SYMBOL_SVG_DIALOG',
    EDIT_ASSIGN_USER_LICENSE = 'EDIT_ASSIGN_USER_LICENSE',
    EDIT_USER_FUNCTIONAL_PERMISSIONS = 'EDIT_USE_PERMISSIONSCTIONALRIGHT',
    EDIT_USER_DBACCESS_PERMISSIONS = 'EDIT_USER_DBACCESS_PERMISSIONS',
    EDIT_ASSIGN_USER_GROUPS = 'EDIT_ASSIGN_USER_GROUPS',
    USERS_SESSIONS_HISTORY = 'USERS_SESSIONS_HISTORY',
    ADD_USER_TO_GROUP = 'ADD_USER_TO_GROUP',
    REMOVE_USER_FROM_GROUP = 'REMOVE_USER_FROM_GROUP',
    MODEL_DIALOG_TYPE = 'MODEL_DIALOG_TYPE',
    MODEL_HISTORY = 'MODEL_HISTORY',
    REFRESH_PAGE_DIALOG = 'REFRESH_PAGE_DIALOG',
    DELETE_LICENSE = 'DELETE_LICENSE',
    PRINT_DIALOG = 'PRINT_DIALOG',
    SEARCH_PATH = 'SEARCH_PATH',
    UPLOAD_VISIO_FILES_DIALOG = 'UPLOAD_VISIO_FILES_DIALOG',
    EDIT_IMPORT_VISIO_PAGE_HEADER = 'EDIT_IMPORT_VISIO_PAGE_HEADER',
    IMPORT_VISIO_ERROR = 'IMPORT_VISIO_ERROR',
    RENAME_OBJECT_DIALOG = 'RENAME_OBJECT_DIALOG',
    RENAME_GRAPH_CELL_DIALOG = 'RENAME_GRAPH_CELL_DIALOG',
    IMPORT_VISIO_DB_CONFLICT = 'IMPORT_VISIO_DB_CONFLICT',
    SELECT_OBJECT_DIALOG = 'SELECT_OBJECT_DIALOG',
    SELECT_EDGE_DIALOG = 'SELECT_EDGE_DIALOG',
    CHECK_OBJECT_DIALOG = 'CHECK_OBJECT_DIALOG',
    IMAGE_EDIT_DIALOG_WIKI = 'IMAGE_EDIT_DIALOG_WIKI',
    TABLE_EDIT_DIALOG_WIKI = 'TABLE_EDIT_DIALOG_WIKI',
    CREATE_INVISIBLE_EDGE_DIALOG = 'CREATE_INVISIBLE_EDGE_DIALOG',
    ADD_USER_PROFILE = 'ADD_USER_PROFILE',
    EXPORT_DIALOG = 'EXPORT_DIALOG',
    SERVER_PROFILE_CHANGE = 'SERVER_PROFILE_CHANGE',
    SPREADSHEET_DIALOG = 'SPREADSHEET_DIALOG',
    SELECT_OBJECT_FOR_MODEL_DECOMPOSITION = 'SELECT_OBJECT_FOR_MODEL_DECOMPOSITION',
    CROSS_SYMBOL_CREATE_DIALOG = 'CROSS_SYMBOL_CREATE_DIALOG',
    ACCEPT_AGREEMENT = 'ACCEPT_AGREEMENT',
    ACCEPT_SILA_AGREEMENT = 'ACCEPT_SILA_AGREEMENT',
    SELECT_MODEL_TYPE = 'SELECT_MODEL_TYPE',
    EVENT_DIALOG = 'EVENT_DIALOG',
    SAVE_SIMULATION_PARAMS_DIALOG = 'SAVE_SIMULATION_PARAMS_DIALOG',
    ADD_SCRIPT_CONTEXT = 'ADD_SCRIPT_CONTEXT',
    ADD_EXTERNAL_SESSION = 'ADD_EXTERNAL_SESSION',
    CONFIRMATION = 'CONFIRMATION',
    APPROVAL = 'APPROVAL',
    DASHBOARD_CREATE_DIALOG = 'DASHBOARD_CREATE_DIALOG',
    DASHBOARD_DELETE_WIDGET_DIALOG = 'DASHBOARD_DELETE_WIDGET_DIALOG',
    DASHBOARD_CELLS_COUNT_DIALOG = 'DASHBOARD_CELLS_COUNT_DIALOG',
    DASHBOARD_SELECT_TABLE_DIALOG = 'DASHBOARD_SELECT_TABLE_DIALOG',
    LOAD_ARIS_FROM_EXCEL = 'LOAD_ARIS_FROM_EXCEL',
    WRITE_COMMENT_DIALOG = 'WRITE_COMMENT_DIALOG',
    DELETE_EDITING_COMMENT_DIALOG = 'DELETE_EDITING_COMMENT_DIALOG',
    ERASE_DELETED_NODE_DIALOG = 'ERASE_DELETED_NODE_DIALOG',
    RESTORE_DELETED_NODE_DIALOG = 'RESTORE_DELETED_NODE_DIALOG',
    SELECT_TREE_ITEM_APPROVAL_DIALOG = 'SELECT_TREE_ITEM_APPROVAL_DIALOG',
    NOTIFICATION_TEMPLATES_DIALOG = 'NOTIFICATION_TEMPLATES_DIALOG',
    SELECT_TREE_ITEM_ADD_FAVORITE_DIALOG = 'SELECT_TREE_ITEM_ADD_FAVORITE_DIALOG',
    NOTIFICATION_SETTINGS_DIALOG = 'NOTIFICATION_SETTINGS_DIALOG',
    METHODOLOGY_GROUP_CREATE_DIALOG = 'METHODOLOGY_GROUP_CREATE_DIALOG',
    MESSAGE_CONTENT_DIALOG = 'MESSAGE_CONTENT_DIALOG',
    CHECK_BROWSER_DIALOG = 'CHECK_BROWSER_DIALOG',
    CREATE_PRESET_IMAGE_DIALOG = 'CREATE_PRESET_IMAGE_DIALOG',
    SCHEDULE_SCRIPT_DIALOG = 'SCHEDULE_SCRIPT_DIALOG',
    SELECT_SCRIPT_TO_SCHEDULE_DIALOG = 'SELECT_SCRIPT_TO_SCHEDULE_DIALOG',
    APPROVAL_TEMPLATE = 'APPROVAL_TEMPLATE',
    DELETE_CONFIRMATION = 'DELETE_CONFIRMATION',
    IMAGE_DOWNLOAD_SETTINGS_DIALOG = 'IMAGE_DOWNLOAD_SETTINGS_DIALOG',
    APPROVAL_DIALOG = 'APPROVAL_DIALOG',
    APPROVAL_TEMPLATE_DIALOG = 'APPROVAL_TEMPLATE_DIALOG',
    MATRIX_HEADER_RENAME_DIALOG = 'MATRIX_HEADER_RENAME_DIALOG',
    CHANGE_PASSWORD_DIALOG = 'CHANGE_PASSWORD_DIALOG',
    EPC_SELECT_MODEL_ROW_NAME_DIALOG = 'EPC_SELECT_MODEL_ROW_NAME_DIALOG',
    SELECT_SYMBOL_TO_REPLACE_ON_PASTE_DIALOG = 'SELECT_SYMBOL_TO_REPLACE_ON_PASTE_DIALOG',
    COPY_DATABASE_DIALOG = 'COPY_DATABASE_DIALOG',
    CREATE_MODEL_TEMPLATE_DIALOG = 'CREATE_MODEL_TEMPLATE_DIALOG',
}

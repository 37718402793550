import React, { useEffect, MouseEvent, useRef, FC } from 'react';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
import theme from './TreeItem.scss';
import draggableIcon from '../../../../resources/icons/baseline-file_copy-24px.svg';
import { TreeItemContextMenuAction, TreeItemType } from '../../models/tree';
import { TreeItemContextMenu } from '../TreeItemContextMenu/TreeItemContextMenu.component';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { noop } from 'lodash-es';
import { useIntl } from 'react-intl';
import TreeItemIcon from './TreeItemIcon.component';
import { NodeId } from '../../../../serverapi/api';
import { TreeNode } from '../../../../models/tree.types';
import modelTypeMessages from '../../../../models/modelType.messages';
import SimulationModelingMessages from '../../../../modules/SimulationModeling/messages/SimulationModeling.messages';
import {
    TPayloadTreeItemSecondaryAction,
    TTreeItemTitleChangeRequestPayload,
    TTreeItemTogglePayload,
} from '../../../../actions/tree.actions.types';
import { DialogsSelectors } from '../../../../selectors/dialogs.selectors';
import { LocalesService } from '../../../../services/LocalesService';
import { getCurrentLocale } from '../../../../selectors/locale.selectors';
import { ExpandStatus } from '../../../../reducers/tree.reducer.types';
import { FolderTypeSelectors } from '../../../../selectors/folderType.selectors';
import { isNodeRenaming, TreeSelectors } from '../../../../selectors/tree.selectors';
import { Locale } from '../../../Header/components/Header/header.types';
import { ModelTypeSelectors } from '../../../../selectors/modelType.selectors';
import { ObjectTypeSelectors } from '../../../../selectors/objectType.selectors';
import {
    treeItemClickDragIcon,
    treeItemCollapseSuccess,
    treeItemContextMenuAction,
    treeItemExpand,
    treeItemSecondaryAction,
    treeItemStartDrag,
    treeItemTitleChangeRequest,
} from '../../../../actions/tree.actions';
import { activeContextMenuChange } from '../../../../actions/contextMenu.actions';
import { NAVIGATOR_STRUCTURE } from '../../../../utils/consts';
import { EdgeTypeSelectors } from '../../../../selectors/edgeType.selectors';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { TreeItemToggler } from './TreeItemToggler.component';
import { useContextMenuDublicateDelete } from '../../../../hooks/useContextMenuDublicateDelete';
import { TTreeNodeWithLevel } from '../../Tree.types';
import { TreeItemName } from '../TreeItemName/TreeItemName.component';

type TTreeItemExtendedProps = {
    rowData: TTreeNodeWithLevel;
    connectedServerId: string;
    disableContextMenu?: boolean;
    specialIcon?: string;
    expandStatus: ExpandStatus;
    treeName: string;
    isDndEnabled?: boolean;
    searchValue?: string;
    onDrop?: (targetNodeId: NodeId, draggedNodeId: NodeId) => void;
};

export const TreeItem: FC<TTreeItemExtendedProps> = (props) => {
    const {
        rowData,
        connectedServerId,
        isDndEnabled,
        disableContextMenu,
        specialIcon,
        expandStatus,
        treeName,
        onDrop,
        searchValue,
    } = props;

    const {
        name,
        nodeId,
        type,
        hasChildren,
        modelTypeId,
        parentNodeId,
        objectTypeId,
        edgeTypeId,
        countChildren,
        folderType,
        deleted,
        userEditDisabled,
    } = rowData;
    const { serverId } = nodeId;

    const intl = useIntl();

    const [isContextMenuVisible, setContextMenuVisible] = useContextMenuDublicateDelete(treeName, nodeId);

    const timer = useRef<ReturnType<typeof setTimeout>>();
    const treeItemContainer = useRef<HTMLDivElement | null>(null);

    const isOpenInDialog: boolean = useSelector(DialogsSelectors.isVisibleDialog);
    const lastOpenDialogType = useSelector(DialogsSelectors.getLastVisibleDialogType);
    const currentLocale: Locale = useSelector(getCurrentLocale);
    const isAllowed: boolean = useSelector(FolderTypeSelectors.getIsTreeItemTypeAllowed(nodeId));
    const presetId: string = useSelector(TreeSelectors.presetById(nodeId));
    const modelType = useSelector(ModelTypeSelectors.byId({ modelTypeId: modelTypeId || '', serverId }, presetId));
    const objectType = useSelector(ObjectTypeSelectors.byId({ objectTypeId: objectTypeId || '', presetId, serverId }));
    const edgeType = useSelector(EdgeTypeSelectors.byId({ edgeTypeId: edgeTypeId || '', presetId, serverId }));
    const isEditing: boolean = useSelector(isNodeRenaming(nodeId));

    const isConnected: boolean = !!connectedServerId;

    const dispatch = useDispatch();
    const onDragHandler = (selectedNode) => dispatch(treeItemStartDrag(selectedNode));
    const onContextMenuAction = (action: TreeItemContextMenuAction) =>
        dispatch(treeItemContextMenuAction({ nodeId, name, action, type, treeName }));

    const onDoubleClick = (payload: TPayloadTreeItemSecondaryAction) => {
        const { ObjectDefinition, EdgeDefinition, Folder, FileFolder } = TreeItemType;
        if (type === ObjectDefinition || type === EdgeDefinition || type === Folder || type === FileFolder) {
            // по экшену treeItemSecondaryAction будут также открываться свойства, но атрибуты не подгружаются.
            // Todo: надо бы одним способом открывать свойства, сейчас это делает еще treeItemOpenPropertyAction
            onContextMenuAction(TreeItemContextMenuAction.PROPERTIES);
        } else {
            dispatch(treeItemSecondaryAction(payload));
        }
    };
    const onTitleChange = (payload: TTreeItemTitleChangeRequestPayload) =>
        dispatch(treeItemTitleChangeRequest(payload));
    const onClickDragIcon = (selectedNode: TreeNode) => dispatch(treeItemClickDragIcon(selectedNode));
    const onContextMenuShow = (selectedNode: TreeNode) => dispatch(activeContextMenuChange(selectedNode));
    const onToggleHandler = (payload: TTreeItemTogglePayload, isExpanded: boolean) => {
        if (isExpanded) {
            dispatch(treeItemCollapseSuccess([payload.nodeId], payload.treeName));
        } else {
            dispatch(treeItemExpand(payload.nodeId, payload.treeName));
        }
    };

    useEffect(() => {
        if (isContextMenuVisible) onContextMenuShow(rowData);
    }, [isContextMenuVisible]);

    const showContextMenu = (e: MouseEvent) => {
        if (disableContextMenu) return;
        e.preventDefault();

        if (
            !isOpenInDialog ||
            (treeName === DialogType.MODEL_DIALOG &&
                (lastOpenDialogType === DialogType.OBJECT_DECOMPOSITION_CREATE ||
                    lastOpenDialogType === DialogType.MODEL_DIALOG))
        ) {
            setContextMenuVisible(true);
        }
    };

    const hideContextMenu = () => setContextMenuVisible(false);

    const onToggle = (event: React.MouseEvent<HTMLInputElement>) => {
        if (!(event.shiftKey || event.ctrlKey || event.metaKey)) {
            const isExpanded = expandStatus === ExpandStatus.OPEN;
            onToggleHandler({ nodeId, treeName }, isExpanded);
        }
    };

    const onClickHandler = (event: React.MouseEvent<HTMLInputElement>) => {
        // во всех деревьях, кроме NAVIGATOR_STRUCTURE запрещены вызовы treeItemSecondaryAction
        const isDoubleClickEnabled = treeName === NAVIGATOR_STRUCTURE;
        event.persist();
        clearTimeout(timer.current!);

        if (event.detail === 1) {
            timer.current = setTimeout(() => onToggle(event), 200);
        } else if (event.detail === 2 && isDoubleClickEnabled) {
            onDoubleClick({ nodeId, type });
        }
    };

    const onDragStart = (e: React.DragEvent<HTMLDivElement>) => {
        const input = (e.target as HTMLElement).querySelector('input');

        if (input) {
            e.preventDefault();

            return;
        }

        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('nodeId', JSON.stringify(nodeId));
        onDragHandler({
            nodeId,
            hasChildren: false,
            countChildren: 0,
            type,
            parentNodeId,
            name,
        });
    };

    const onDragEnter = (e: React.DragEvent<HTMLElement>) => {
        e.preventDefault();

        return true;
    };

    const onDragOver = (e: React.DragEvent<HTMLElement>) => {
        e.preventDefault();

        if (treeItemContainer.current) {
            e.dataTransfer.setDragImage(treeItemContainer.current, 0, 0);
        }
    };

    const onDropHandler = (e: React.DragEvent<HTMLDivElement>) => {
        if (e.dataTransfer.effectAllowed === 'move') {
            const treeItemNodeId: NodeId = JSON.parse(e.dataTransfer.getData('nodeId'));
            if (onDrop && treeItemNodeId.id !== nodeId.id) {
                onDrop(nodeId, treeItemNodeId);
            }
        }
    };

    const createSuffix = (): string => {
        let valueTypeName: string | undefined;

        switch (type) {
            case TreeItemType.Model:
                valueTypeName = LocalesService.internationalStringToString(modelType?.multilingualName, currentLocale);
                break;
            case TreeItemType.ObjectDefinition:
                valueTypeName = objectType?.name || '';
                break;
            case TreeItemType.SimulationModeling:
                valueTypeName = intl.formatMessage(SimulationModelingMessages.simulationModel);
                break;
            case TreeItemType.Matrix:
                valueTypeName = intl.formatMessage(modelTypeMessages.MATRIX);
                break;
            case TreeItemType.Wiki:
                valueTypeName = intl.formatMessage(modelTypeMessages.WIKI);
                break;
            case TreeItemType.Kanban:
                valueTypeName = intl.formatMessage(modelTypeMessages.KANBAN);
                break;
            case TreeItemType.EdgeDefinition:
                valueTypeName = edgeType?.name || '';
                break;
            default:
                valueTypeName = '';
        }
        if (valueTypeName) {
            return ` [${valueTypeName}]`;
        }

        return '';
    };

    const hasToggler: boolean =
        (type === TreeItemType.Server && isConnected) || (type !== TreeItemType.Server && hasChildren);

    const containerClassName = classnames(theme.container, {
        [theme.container_expandable]: hasToggler,
    });

    let treeItemAttributes: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> = {
        className: containerClassName,
        onContextMenu: showContextMenu,
        draggable: false,
        onDragStart: noop,
        onClick: onClickHandler,
        onDrop: onDropHandler,
        onDragOver,
        onDragEnter,
    };

    const isItemDndEnabled = [
        TreeItemType.Model,
        TreeItemType.ObjectDefinition,
        TreeItemType.Folder,
        TreeItemType.Script,
        TreeItemType.ScriptFolder,
        TreeItemType.Wiki,
        TreeItemType.SimulationModeling,
        TreeItemType.Matrix,
        TreeItemType.File,
        TreeItemType.FileFolder,
        TreeItemType.Spreadsheet,
        TreeItemType.Dashboard,
    ].includes(type);

    if (isItemDndEnabled && isDndEnabled) {
        treeItemAttributes = {
            ...treeItemAttributes,
            onDragStart,
            draggable: true,
        };
    }
    const createClickDragIconHandler = () => {
        onClickDragIcon({ nodeId, type, parentNodeId, name, hasChildren, countChildren });
    };

    const onTitleChangeHandler = (title: string) => onTitleChange({ nodeId, title: title.trim() });

    return (
        <>
            {isContextMenuVisible && (
                <TreeItemContextMenu rowData={rowData} visible={isContextMenuVisible} hideContextMenu={hideContextMenu}>
                    <div />
                </TreeItemContextMenu>
            )}

            <div {...treeItemAttributes} ref={treeItemContainer}>
                {!hasToggler && type === TreeItemType.Server && <div className={theme.fakeToggler} />}
                {hasToggler && <TreeItemToggler expandStatus={expandStatus} />}
                <div className={theme.entityIconContainer}>
                    <TreeItemIcon {...{ nodeId, type, isConnected, specialIcon, folderType }} />
                </div>
                <div
                    className={classnames(theme.title, {
                        [theme.notAllowedTreeItem]: !isAllowed,
                        [theme.deletedTreeItem]: !!deleted,
                        [theme.userEditDisabledTreeItem]: userEditDisabled,
                    })}
                >
                    {
                        <TreeItemName
                            name={name}
                            isEditing={isEditing}
                            suffix={createSuffix()}
                            onChange={onTitleChangeHandler}
                            nodeId={nodeId}
                            type={type}
                            searchValue={searchValue}
                        />
                    }
                </div>
                {type === TreeItemType.ObjectDefinition && isDndEnabled && (
                    <span className={theme.draggableIndicator} onClick={createClickDragIconHandler}>
                        <Icon spriteSymbol={draggableIcon} />
                    </span>
                )}
            </div>
        </>
    );
};

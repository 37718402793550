import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { openDialog } from '../../../../../actions/dialogs.actions';
import { exportPreset } from '../../../../../actions/methodologySetting.actions';
import {
    createModelType,
    deleteModelType,
    editModelType,
    transferModelType,
} from '../../../../../actions/presetSettings/presetSettingsModelType.actions';
import { TPreset } from '../../../../../models/preset.types';
import { TreeNode } from '../../../../../models/tree.types';
import icModel from '../../../../../resources/icons/ic-tree-model-active.svg';
import { getCurrentLocale } from '../../../../../selectors/locale.selectors';
import { PresetSettingsModelTypeSelectors } from '../../../../../selectors/presetSettings/presetSettingsModelType.selectors';
import {
    ModelType,
    ModelTypeGroup,
    PresetElementTransferType,
    KanbanBoardType,
    MatrixType,
    ReportType,
} from '../../../../../serverapi/api';
import { DialogType } from '../../../../DialogRoot/DialogRoot.constants';
import { TreeItemType } from '../../../../Tree/models/tree';
import messages from '../../messages/Presets.messages';
import { GroupSelectionDialog } from './Dialog/GroupSelectionDialog/GroupSelectionDialog.component';
import { DeleteSelected, ExportButton, ImportButton, MoveSelected, TabHeader } from './Header/TabHeader.component';
import { GroupedTypesTable } from './util/GroupedTypesTable.component';
import theme from './Presets.scss';
import { LocalesService } from '../../../../../services/LocalesService';
import { PresetSettingsKanbanModelTypeSelectors } from '../../../../../selectors/presetSettings/presetSettingsKanbanModelType.selectors';
import { TCommonType } from './util/GroupedTypesTable.types';
import {
    deleteKanbanModelType,
    editKanbanModelType,
    transferKanbanModelType,
} from '../../../../../actions/presetSettings/presetSettingsKanbanModelType.actions';
import { isKanbanBoardType } from './util/KanbanCardEditor.utils';
import { Alert } from 'antd';
import { getMetodologyElementsDeleteMessages } from './util/metodologyElementsDeleteMessages.utils';
import { ExperimentalFeatures } from '@/models/ExperimentalFeatures';
import { ModelTypeGroupSelectors } from '../../../../../selectors/modelTypeGroup.selectors';
import { ButtonWithDropdown } from './Header/ButtonWithDropdown.component';
import icFolder from '../../../../../resources/icons/group.svg';
import icImport from '../../../../../resources/icons/Import.svg';
import { PRESET_IMPORT_EXTENSIONS } from '../../../../FileUpload/types/FileUploadDialog.constants';
import { PresetSettingsMatrixModelTypeSelectors } from '@/selectors/presetSettings/presetSettingsMatrixModelType.selectors';
import {
    deleteMatrixModelType,
    editMatrixModelType,
    transferMatrixModelType,
} from '@/actions/presetSettings/presetSettingsMatrixModelType.actions';
import { isMatrixModelType } from '@/modules/Matrix/utils/Matrix.utils';
import { GeneralModelTypeDiscriminator } from '@/models/ModelTypes';
import {
    deleteReportModelType,
    editReportModelType,
    transferReportModelType,
} from '@/actions/presetSettings/presetSettingsReportModelType.actions';
import { PresetSettingsReportModelTypeSelectors } from '@/selectors/presetSettings/presetSettingsReportModelType.selectors';
import { isReportModelType } from './util/ReportModelType.utils';

type TModelTypesTablProps = {
    disabled: boolean;
    serverNode: TreeNode;
    preset: TPreset;
    editModelTypeGroup: (modelTypeGroup: ModelTypeGroup) => void;
    createModelTypeGroup: () => void;
    deleteModelTypeGroups: (ModelTypeGroups: ModelTypeGroup[]) => void;
};

const ModelTypesTab: FC<TModelTypesTablProps> = (props) => {
    const { disabled, serverNode, preset, createModelTypeGroup, deleteModelTypeGroups, editModelTypeGroup } = props;
    const presetId = preset.id;
    const { serverId } = serverNode.nodeId;
    const intl = useIntl();
    const [searchFilter, setSearchFilter] = useState<string>('');
    const [selectedModelTypes, setSelectedModelTypes] = useState<ModelType[]>([]);
    const [selectedModelTypeGroups, setSelectedModelTypeGroups] = useState<ModelTypeGroup[]>([]);
    const [selectGroupDialogVisible, setSelectGroupDialogVisible] = useState<boolean>(false);
    const [saveRequired, setSaveRequired] = useState<boolean>(false);
    const currentLocale = useSelector(getCurrentLocale);

    const selected = selectedModelTypes.length || selectedModelTypeGroups.length;

    const modelTypeGroups: ModelTypeGroup[] = useSelector(
        ModelTypeGroupSelectors.byPresetIdExcludeDeleted({
            serverId,
            presetId,
        }),
    );
    const deletedModelTypeGroups: ModelTypeGroup[] = useSelector(
        ModelTypeGroupSelectors.deletedModelTypeGroups({ serverId, presetId }),
    );

    const dispatch = useDispatch();
    const modelTypesData = useSelector(PresetSettingsModelTypeSelectors.byServerIdPresetId({ serverId, presetId }));
    const matrixTypesData = useSelector(PresetSettingsMatrixModelTypeSelectors.byPresetId(presetId));
    const reportTypesData = useSelector(PresetSettingsReportModelTypeSelectors.byPresetId(presetId));
    const kanbanModelTypesData = useSelector(PresetSettingsKanbanModelTypeSelectors.byPresetId(presetId));
    const modelTypes: ModelType[] = Object.values(modelTypesData.byId || {});
    const matrixTypes: MatrixType[] = Object.values(matrixTypesData.byId || {});
    const reportTypes: ReportType[] = Object.values(reportTypesData.byId || {});
    const kanbanModelTypes: TCommonType[] = Object.values(kanbanModelTypesData.byId || {}) as unknown as TCommonType[];
    const allTypes: TCommonType[] = [
        ...modelTypes.filter((mt) => mt.id !== TreeItemType.Wiki.toLowerCase()),
        ...matrixTypes,
        ...reportTypes,
        ...kanbanModelTypes,
    ];
    const { deleteMessage: deleteModelsMessage, deleteGroupsMessage } = getMetodologyElementsDeleteMessages({
        selectedModelTypes,
        selectedModelTypeGroups,
    });

    const deleteModelTypeHandler = (modelType: ModelType | MatrixType) => {
        setSaveRequired(true);
        if (modelType.discriminator === GeneralModelTypeDiscriminator.MATRIX) {
            dispatch(deleteMatrixModelType({ matrixModelTypesForDelete: [modelType], presetId }));
        }
        if (modelType.discriminator === GeneralModelTypeDiscriminator.REPORT) {
            dispatch(deleteReportModelType({ reportModelTypesForDelete: [modelType], presetId }));
        }
        if (modelType.discriminator === GeneralModelTypeDiscriminator.MODEL_TYPE || !modelType.discriminator) {
            dispatch(deleteModelType({ serverId, presetId, modelTypesForDelete: [modelType as ModelType] }));
        }
        setSelectedModelTypes(selectedModelTypes.filter((type) => type.id !== modelType.id));
    };

    const editModelTypeHandler = (modelType: ModelType | MatrixType) => {
        if (modelType.discriminator === GeneralModelTypeDiscriminator.MODEL_TYPE || !modelType.discriminator) {
            dispatch(
                editModelType({
                    serverNode,
                    preset,
                    modelType: modelType as ModelType,
                }),
            );
        }

        if (modelType.discriminator === GeneralModelTypeDiscriminator.MATRIX) {
            dispatch(
                editMatrixModelType({
                    serverNodeId: serverNode.nodeId,
                    presetId,
                    matrixModelType: modelType,
                }),
            );
        }

        if (modelType.discriminator === GeneralModelTypeDiscriminator.REPORT) {
            dispatch(
                editReportModelType({
                    serverNodeId: serverNode.nodeId,
                    presetId,
                    reportModelType: modelType,
                }),
            );
        }
    };

    return (
        <div className={theme.container}>
            <TabHeader
                buttons={[
                    ExportButton.build(
                        () => {
                            dispatch(
                                exportPreset(
                                    preset,
                                    serverId,
                                    PresetElementTransferType.model,
                                    selectedModelTypes.map((mt) => mt.id),
                                ),
                            );
                        },
                        intl.formatMessage(messages.export),
                        !(allTypes.length || modelTypeGroups.length),
                        saveRequired,
                    ),
                    DeleteSelected.build(
                        () => {
                            if (selectedModelTypes.length) {
                                const modelTypesForDelete = selectedModelTypes.filter(
                                    (type) =>
                                        !isKanbanBoardType(type) &&
                                        !isMatrixModelType(type) &&
                                        !isReportModelType(type),
                                );

                                const kanbanModelTypesForDelete = selectedModelTypes.filter((type) =>
                                    isKanbanBoardType(type),
                                ) as unknown as KanbanBoardType[];

                                const matrixModelTypesForDelete: MatrixType[] = selectedModelTypes.filter((type) =>
                                    isMatrixModelType(type),
                                );

                                const reportModelTypesForDelete: ReportType[] = selectedModelTypes.filter((type) =>
                                    isReportModelType(type),
                                );

                                dispatch(
                                    deleteKanbanModelType({
                                        presetId,
                                        kanbanModelTypesForDelete,
                                    }),
                                );
                                dispatch(deleteModelType({ serverId, presetId, modelTypesForDelete }));
                                dispatch(deleteMatrixModelType({ presetId, matrixModelTypesForDelete }));
                                dispatch(deleteReportModelType({ presetId, reportModelTypesForDelete }));
                                setSelectedModelTypes([]);
                            }
                            if (selectedModelTypeGroups.length) {
                                setSelectedModelTypeGroups([]);
                                deleteModelTypeGroups(selectedModelTypeGroups);
                            }
                            setSaveRequired(true);
                        },
                        disabled || !selected,
                        undefined,
                        intl.formatMessage(messages.deleteModelsDialogTitle),
                        <Alert
                            message={
                                <>
                                    {deleteGroupsMessage}
                                    {deleteModelsMessage}
                                </>
                            }
                            type="warning"
                        />,
                    ),
                    MoveSelected.build(() => setSelectGroupDialogVisible(true), disabled || !selected),
                ]}
                customButtons={
                    <ButtonWithDropdown
                        title={intl.formatMessage(messages.create)}
                        itemButtons={[
                            {
                                name: messages.newModel,
                                disabled: disabled || !modelTypeGroups?.length,
                                onAction: () => {
                                    ExperimentalFeatures.isEnable()
                                        ? dispatch(
                                              openDialog(DialogType.SELECT_MODEL_TYPE, {
                                                  serverNode,
                                                  preset,
                                              }),
                                          )
                                        : dispatch(
                                              createModelType({
                                                  serverNode,
                                                  preset,
                                              }),
                                          );
                                },
                            },
                            {
                                name: messages.newGroup,
                                disabled,
                                icon: icFolder,
                                onAction: createModelTypeGroup,
                            },
                            ImportButton.build(
                                () => {
                                    dispatch(
                                        openDialog(DialogType.UPLOAD_PRESET_DIALOG, {
                                            serverNode,
                                            type: PresetElementTransferType.model,
                                            preset,
                                            filters: PRESET_IMPORT_EXTENSIONS,
                                        }),
                                    );
                                },
                                messages.import,
                                disabled,
                                saveRequired,
                                icImport,
                            ),
                        ]}
                    />
                }
                onSearchChange={setSearchFilter}
            />
            <GroupedTypesTable
                types={allTypes}
                typeGroups={modelTypeGroups}
                searchFilter={searchFilter}
                actionsDisabled={disabled}
                icon={icModel}
                onSelectType={(checkedTypes: ModelType[]) => {
                    setSelectedModelTypes(
                        checkedTypes.filter((type) => {
                            return (
                                !modelTypesData.modelTypesForDelete.some((modelType) => modelType.id === type.id) &&
                                !matrixTypesData.matrixModelTypesForDelete.some(
                                    (matrixType) => matrixType.id === type.id,
                                ) &&
                                !deletedModelTypeGroups.some((modelTypeGroup) => modelTypeGroup.id === type.groupId)
                            );
                        }),
                    );
                }}
                selectedTypes={selectedModelTypes}
                onSelectGroup={setSelectedModelTypeGroups as any}
                selectedTypeGroups={selectedModelTypeGroups}
                onDeleteType={deleteModelTypeHandler}
                onDeleteKanbanType={(kanbanModelType: KanbanBoardType) => {
                    setSaveRequired(true);
                    dispatch(
                        deleteKanbanModelType({
                            presetId,
                            kanbanModelTypesForDelete: [kanbanModelType],
                        }),
                    );
                    setSelectedModelTypes(selectedModelTypes.filter((type) => type.id !== kanbanModelType.id));
                }}
                onDeleteGroup={(modelTypeGroup: ModelTypeGroup) => {
                    setSaveRequired(true);
                    deleteModelTypeGroups([modelTypeGroup]);
                    setSelectedModelTypeGroups(selectedModelTypeGroups.filter((type) => type.id !== modelTypeGroup.id));
                    setSelectedModelTypes(
                        selectedModelTypes.filter((type) => type.modelTypeGroup.id !== modelTypeGroup.id),
                    );
                }}
                onEditType={editModelTypeHandler}
                onEditKanbanType={(kanbanModelType: KanbanBoardType) => {
                    dispatch(
                        editKanbanModelType({
                            serverNodeId: serverNode.nodeId,
                            kanbanModelType,
                            presetId: preset.id,
                        }),
                    );
                }}
                onEditGroup={editModelTypeGroup}
                columns={[
                    {
                        label: intl.formatMessage(messages.description),
                        dataKey: 'multilingualDescription',
                        width: 200,
                        cellRenderer: ({ rowData }) =>
                            LocalesService.internationalStringToString(rowData.multilingualDescription, currentLocale),
                    },
                ]}
                titleDeleteMessage={messages.deleteModelsDialogTitle}
                deleteGroupMessage={messages.deleteModelGroups}
                deleteElMessage={messages.deleteModels}
            />
            {selectGroupDialogVisible && (
                <GroupSelectionDialog
                    groups={modelTypeGroups}
                    onSubmit={(group) => {
                        if (group) {
                            const modelTypesForTransfer: ModelType[] = selectedModelTypes.filter(
                                (type) =>
                                    !isKanbanBoardType(type) && !isMatrixModelType(type) && !isReportModelType(type),
                            );
                            const kanbanModelTypesForTransfer = selectedModelTypes.filter((type) =>
                                isKanbanBoardType(type),
                            ) as unknown as KanbanBoardType[];
                            const matrixTypesForTransfer: MatrixType[] = selectedModelTypes.filter((type) =>
                                isMatrixModelType(type),
                            );
                            const reportTypesForTransfer: ReportType[] = selectedModelTypes.filter((type) =>
                                isReportModelType(type),
                            );

                            dispatch(
                                transferKanbanModelType({
                                    presetId,
                                    kanbanModelTypes: kanbanModelTypesForTransfer.map((modelType) => ({
                                        ...modelType,
                                        groupId: group.id,
                                    })),
                                }),
                            );
                            dispatch(
                                transferMatrixModelType({
                                    presetId,
                                    matrixModelTypes: matrixTypesForTransfer.map((modelType) => ({
                                        ...modelType,
                                        groupId: group.id,
                                    })),
                                }),
                            );
                            dispatch(
                                transferReportModelType({
                                    presetId,
                                    reportModelTypes: reportTypesForTransfer.map((modelType) => ({
                                        ...modelType,
                                        groupId: group.id,
                                    })),
                                }),
                            );
                            dispatch(
                                transferModelType({
                                    presetId,
                                    serverId,
                                    modelTypes: modelTypesForTransfer.map((modelType) => ({
                                        ...modelType,
                                        groupId: group.id,
                                        modelTypeGroup: group as ModelTypeGroup,
                                    })),
                                }),
                            );
                        }
                        setSelectGroupDialogVisible(false);
                        setSaveRequired(true);
                    }}
                    onClose={() => setSelectGroupDialogVisible(false)}
                />
            )}
        </div>
    );
};

export { ModelTypesTab };

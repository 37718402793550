import type { FileNodeDTO } from '../serverapi/api';

const imageExtensions: (string | undefined)[] = ['jpg', 'gif', 'png'];

export const isImageExtension = (ext?: string) => imageExtensions.includes(ext?.toLowerCase());

export const isImageFile = (file?: FileNodeDTO): boolean => isImageExtension(file?.extension);

export const getExtension = (name?: string): string | undefined => {
    const nameAndExtension = name?.split('.');

    return nameAndExtension && nameAndExtension.length >= 2 ? nameAndExtension.pop() : undefined;
};

export const getFileExtension = (file: FileNodeDTO): string | undefined => file?.extension || getExtension(file?.name);

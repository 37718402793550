import { addIfExist } from '@/utils/redux.utils';
import { SET_AIP_SEARCH_DATA } from '../actionsTypes/assistantInterface.actionTypes';
import { TSearchAipState } from './searchAssistantInterface.reducer.types';

const initial: { [key: string]: TSearchAipState } = {};

export const SearchAipReducer = (state = initial, action) => {
    switch (action.type) {
        case SET_AIP_SEARCH_DATA: {
            const { id, searchResult, isLoading } = action.payload;

            if (id) {
                const newState = { ...state };

                newState[id] = {
                    searchResult: addIfExist(searchResult, state[id]?.searchResult || []),
                    isLoading: addIfExist(isLoading, state[id]?.isLoading || false),
                };

                return newState;
            }

            return state;
        }

        default: {
            return state;
        }
    }
};


import type { TNavigatorPropertiesData } from '../../../models/navigatorPropertiesSelectorState.types';
import type { TPeriodRange } from '../../../utils/date.time.utils.types';
import type { Locale } from '../../Header/components/Header/header.types';
import type { TAttribute, TAttributeUrlValues } from './AttributeTab.types';
import type {
    ApprovalDTO,
    AttributeType,
    AttributeValue,
    AttributeValueMultiSelect,
    AttributeValueNode,
    AttributeValuePrincipal,
    AttributeValueString,
    AttributeValueUrl,
    AttributeValueValueTypeEnum,
    FolderType,
    InternationalString,
    PrincipalAttributeTypeSettingsFormatEnum,
    PrincipalDescriptor,
    AttributeValueQueryMultiSelect,
    AttributeValueQuerySelect,
    NodeTypeEnum,
} from '../../../serverapi/api';
import messages from '../../Navigator/messages/Navigator.messages';
import { dateFormat, dateTimeFormat, timeFormat, timestampToStringDate } from '../../../utils/date.time.utils';
import { LocalesService } from '../../../services/LocalesService';
import { edgeArrowTypeNames } from '../../../models/edge-arrow';
import { edgeStylesNames } from '../../../models/edge-style';
import {
    systemAttributeEdgeStyle,
    systemAttributeFolderType,
    systemAttributeTypeArrow,
} from '../../../models/properties/accessible-properties.constants';
import { folderTypeNames } from '../../../models/folderTypes';
import { PrincipalType } from '../../../models/security/PrincipalType';
import { ApprovalStageDTOStatus } from '@/modules/ApprovalDialog/ApprovalDialog.types';
import { AttributeValueType } from '../../FloatingAttributes/components/AttributesEditor/Attribute.types';

export const DELETED_USER_ID = -1;

export const DELETED_GROUP_NAME = 'deleted group';

export const jsonParse = (value) => {
    if (!value || value === true) {
        return null;
    }
    try {
        return JSON.parse(value);
    } catch (e) {
        return null;
    }
};

export const booleanValueToString = (value: any): string => {
    const intl = LocalesService.useIntl();

    return value ? intl.formatMessage(messages.yes) : intl.formatMessage(messages.no);
};

export const trimStringValue = (value: string | undefined): string => {
    return value !== undefined
        ? `${value}`
              .replace(/&nbsp;|<\/?div>|<\/?br>/gi, ' ')
              .replace(/  +/g, ' ')
              .trim()
        : '';
};

export const trimMultiLangValue = (multiLangValue: InternationalString | undefined): InternationalString => {
    const values = {};
    if (multiLangValue) {
        Object.keys(multiLangValue).forEach((key) => {
            values[key] = trimStringValue(multiLangValue[key]);
        });
    }

    return values as InternationalString;
};

export const storageValueToString = (
    value: AttributeValue | undefined | null, // valueType в данных классах имеет разный тип : TValueTypeEnum | AttributeValueValueTypeEnum, они отличаются на одно знaчение
    currentLocale: Locale,
    options?: {
        system?: boolean;
        attributeTypes?: AttributeType[];
        folderTypes?: FolderType[];
        principals?: PrincipalDescriptor[];
    },
): string => {
    const { system, attributeTypes, folderTypes = [], principals = [] } = options || {};

    if (value === undefined || value === null) {
        return '';
    }

    const intl = LocalesService.useIntl();

    switch (value.valueType) {
        // case 'FILE':
        case AttributeValueType.STRING:
        case AttributeValueType.MULTI_STRING: {
            // Для системных значений берем пока поле value из AttributeValueString
            if (system && value.id !== 'multilingualName') {
                return trimStringValue(value?.value);
            }

            const { str } = value as AttributeValueString;

            return LocalesService.internationalStringToString(str, currentLocale);
        }
        case AttributeValueType.URL: {
            const { name } = value as AttributeValueUrl;

            return LocalesService.internationalStringToString(name, currentLocale);
        } // case 'ENUM':
        case AttributeValueType.NUMERIC:
        case AttributeValueType.INTEGER:
        case AttributeValueType.JSON: {
            return value?.value?.toString() || '';
        }
        case AttributeValueType.SELECT: {
            const attributeType = attributeTypes?.find((at) => at.id === value?.typeId);
            let values = attributeType?.selectPropertyValues;

            // типы стрелок, пришлось затащить отдельно
            if (!values && value.typeId === systemAttributeTypeArrow) {
                values = edgeArrowTypeNames(currentLocale, intl);
            }
            if (!values && value.typeId === systemAttributeEdgeStyle) {
                values = edgeStylesNames(currentLocale, intl);
            }
            if (!values && value.typeId === systemAttributeFolderType) {
                values = folderTypeNames(folderTypes, currentLocale, intl);
            }

            const result = values?.find((v) => v.id === value.value)?.value;

            return LocalesService.internationalStringToString(result, currentLocale as Locale);
        }

        case AttributeValueType.TIME_WITHOUT_TIMEZONE:
        case AttributeValueType.TIME: {
            if (Array.isArray(value.value) || value.value === '' || value.value === undefined) {
                return '';
            }
            const timestamp: number = +value.value.toString();

            return timestampToStringDate(timestamp, timeFormat, value.valueType === 'TIME_WITHOUT_TIMEZONE');
        }

        case AttributeValueType.DATE: {
            if (Array.isArray(value.value) || value.value === '' || value.value === undefined) {
                return '';
            }
            const timestamp: number = +value.value.toString();

            return timestampToStringDate(timestamp, dateFormat);
        }

        case AttributeValueType.DATE_TIME_WITHOUT_TIMEZONE:
        case AttributeValueType.DATE_TIME: {
            if (Array.isArray(value.value) || value.value === '' || value.value === undefined) {
                return '';
            }
            const timestamp: number = +value.value.toString();

            return timestampToStringDate(timestamp, dateTimeFormat, value.valueType === 'DATE_TIME_WITHOUT_TIMEZONE');
        }

        case AttributeValueType.MULTI_SELECT: {
            const mSelect = value as AttributeValueMultiSelect;
            const attributeType = attributeTypes?.find((at) => at.id === value?.typeId);

            const results = mSelect.valueIds?.map((id) => {
                const sName = attributeType?.selectPropertyValues?.find((v) => v.id === id)?.value;

                return sName ? LocalesService.internationalStringToString(sName, currentLocale as Locale) : '';
            });

            return Array.isArray(results) ? results?.filter((i) => i)?.join(', ') || '' : '';
        }

        case AttributeValueType.NODE: {
            const { notFound, linkedNodeId, name } = value as AttributeValueNode;

            if (notFound && linkedNodeId) {
                return intl.formatMessage(messages.itemRemoved);
            }

            return LocalesService.internationalStringToString(name, currentLocale);
        }

        case AttributeValueType.BOOLEAN: {
            return value.value?.toString() === 'true'
                ? intl.formatMessage(messages.yes)
                : intl.formatMessage(messages.no);
        }

        case AttributeValueType.PERIOD: {
            const periodObj = jsonParse(value.value) as TPeriodRange | null;
            if (!periodObj?.from || !periodObj?.to) {
                return '';
            }
            const from = timestampToStringDate(+periodObj.from, dateFormat);
            const to = timestampToStringDate(+periodObj.to, dateFormat);
            if (!from || !to) {
                return '';
            }

            return `${from} - ${to}`;
        }

        case AttributeValueType.PRINCIPAL: {
            const { logins, groupIds } = value as AttributeValuePrincipal;
            const principalAttributeTypeSettings = attributeTypes?.find(
                (at) => at.id === value?.typeId,
            )?.principalAttributeTypeSettings;
            const { selectedExistedUsers: users } = getUsersSelectedData(principals, logins);
            const { selectedExistedGroups: groups } = getGroupsSelectedData(principals, groupIds);
            const participants = [
                ...users.filter((el) => !el.blocked).map(formatUserName(principalAttributeTypeSettings?.format)),
                ...groups.filter((el) => !el.blocked).map((group) => group.login),
            ].slice(0, 5);

            return participants.join(', ');
        }

        case AttributeValueType.QUERY_SELECT: {
            const { attributeValueQuery } = value as AttributeValueQuerySelect;

            return attributeValueQuery?.value || '';
        }

        case AttributeValueType.QUERY_MULTI_SELECT: {
            const { attributeValueQuery } = value as AttributeValueQueryMultiSelect;

            return attributeValueQuery?.map(({ value }) => value).join(', ') || '';
        }

        default:
            return value.toString();
    }
};

export const checkAttributeValues = (
    str: InternationalString | undefined,
    value: TAttributeUrlValues | string,
    currentLocale: Locale,
): InternationalString => {
    if (!str && typeof value === 'string') {
        return { [currentLocale]: value } as InternationalString;
    }
    if (str && typeof value === 'object') {
        return { ...value.url } as InternationalString;
    }
    if (str && typeof value === 'string') {
        return { ...str, [currentLocale]: value } as InternationalString;
    }

    return {};
};

// Костыль, т.к. свагер на бэкенде возвращает value как строку
export const checkBooleanValue = (value: any): boolean => {
    if (typeof value === 'string' && value === 'true') {
        return true;
    }
    if (typeof value === 'string' && value === 'false') {
        return false;
    }

    return Boolean(value);
};

export const checkCurrentLocaleDataFilled = (
    record: AttributeValue,
    curLocale: string,
    valueType: AttributeValueValueTypeEnum,
): boolean => {
    let isCurrentLocaleDataFilled: boolean = true;
    if (record.typeId !== 'SYSTEM') {
        if (valueType === 'STRING' || valueType === 'MULTI_STRING')
            isCurrentLocaleDataFilled = !!(record as AttributeValueString).str?.[curLocale];
        if (valueType === 'URL') {
            isCurrentLocaleDataFilled = !!(record as AttributeValueNode).name?.[curLocale];
        }
    }

    return isCurrentLocaleDataFilled;
};

export const getUsersSelectedData = (users: PrincipalDescriptor[], logins?: string[]) => {
    const selectedExistedUsers = users?.filter((el) => logins?.some((login) => login === el.login)) || [];
    const selectedDeletedUsers =
        logins
            ?.filter((userLogin) => !selectedExistedUsers.some((user) => userLogin === user.login))
            .map(
                (deletedUser) =>
                    ({
                        id: DELETED_USER_ID,
                        login: deletedUser,
                        blocked: false,
                        principalType: PrincipalType.USER,
                    } as PrincipalDescriptor),
            ) || [];

    return {
        allSelectedUsers: [...selectedExistedUsers, ...selectedDeletedUsers],
        selectedExistedUsers,
        selectedDeletedUsers,
    };
};

export const getGroupsSelectedData = (groups: PrincipalDescriptor[], groupIds?: number[]) => {
    const selectedExistedGroups = groups?.filter((el) => groupIds?.some((groupId) => groupId === el.id)) || [];
    const selectedDeletedGroups =
        groupIds
            ?.filter((groupId) => !selectedExistedGroups.some((group) => groupId === group.id))
            .map(
                (deletedGroup) =>
                    ({
                        id: deletedGroup,
                        login: DELETED_GROUP_NAME,
                        blocked: false,
                        principalType: PrincipalType.GROUP,
                    } as PrincipalDescriptor),
            ) || [];

    return {
        allSelectedGroups: [...selectedExistedGroups, ...selectedDeletedGroups],
        selectedExistedGroups,
        selectedDeletedGroups,
    };
};

export const getPrincipalSelectedData = (
    users: PrincipalDescriptor[],
    groups: PrincipalDescriptor[],
    value: AttributeValuePrincipal,
) => {
    const { allSelectedUsers, selectedDeletedUsers } = getUsersSelectedData(users, value?.logins);
    const { allSelectedGroups, selectedDeletedGroups } = getGroupsSelectedData(groups, value?.groupIds);

    return {
        users: [...users, ...selectedDeletedUsers],
        groups: [...groups, ...selectedDeletedGroups],
        selectedUsers: allSelectedUsers,
        selectedGroups: allSelectedGroups,
    };
};

export const formatUserName =
    (format: PrincipalAttributeTypeSettingsFormatEnum = 'LOGIN') =>
    (user: PrincipalDescriptor) => {
        const formatMap = {
            LOGIN: user.login,
            LOGIN_LASTNAME_NAME_MIDDLENAME: `${user.login} (${getUserFIO(user)})`,
            LASTNAME_NAME_MIDDLENAME: getUserFIO(user),
        };

        return formatMap?.[format] || '';
    };

export const getUserFIO = (user: PrincipalDescriptor) =>
    `${[user.lastname, user.name, user.middlename].filter((el) => !!el).join(' ')}`;

export const ATTRIBUTE_PRIORITY: { [key: string]: number } = {
    multilingualName: -30,
    folderType: -15,
    objectType: -15,
    edgeType: -15,
    modelTypeId: -15,
    symbol: -10,
    unknown: -1,
    nodeId: 10,
    direction: 30,
    sourceObject: 31,
    targetObject: 32,
    linkedObjectTypes: 40,
    updatedBy: 50,
    updatedAt: 60,
    createdBy: 70,
    createdAt: 80,
    confidential: 100,
    publishedBy: 110,
    publishedAt: 120,
};

const attributesComparator = (a: TAttribute, b: TAttribute): number => {
    // на последнем месте атрибут конфиденциально
    if (a.key === 'confidential') return 1;
    if (b.key === 'confidential') return -1;

    const aPriority: number = ATTRIBUTE_PRIORITY[a.id || a.key || ''] || 0;
    const bPriority: number = ATTRIBUTE_PRIORITY[b.id || b.key || ''] || 0;
    // сортируем системные и добавляемые атрибуты в соответствии с приоритетом
    if (aPriority !== bPriority) {
        return aPriority - bPriority;
    }

    // если признак редактируемости атрибутов разный, сначала редактируемые
    if (b?.editable !== a?.editable) {
        return Number(b.editable) - Number(a.editable);
    }

    // сортируем по алфавиту
    return (a.name || '').localeCompare(b.name || '');
};

export const sortAttributes = (attributes: TAttribute[]) => {
    return attributes.sort((a, b) => attributesComparator(a, b));
};

export const sortProperties = (properties: TNavigatorPropertiesData, keyFilter: (key: string) => boolean) => {
    const propVal = Object.keys(properties)
        .filter(keyFilter)
        .map((propKey) => properties[propKey]);

    return propVal.sort((a, b) => {
        const priorityA = ATTRIBUTE_PRIORITY[a.descriptor.key] || ATTRIBUTE_PRIORITY.unknown;
        const priorityB = ATTRIBUTE_PRIORITY[b.descriptor.key] || ATTRIBUTE_PRIORITY.unknown;
        if (priorityA === priorityB) {
            return a.descriptor.getTitle().localeCompare(b.descriptor.getTitle());
        }

        return priorityA - priorityB;
    });
};

export enum UML_STRING_COLUMN_KEY {
    MULTILINGUAL_NAME = 'multilingualName',
    DEF_VALUE = 'defValue',
}

export const getUmlColumnId = (objectId: string, columnKey: UML_STRING_COLUMN_KEY): string => `${objectId}${columnKey}`;

export const getUmlObjectIdFromColumnId = (columnId: string, columnKey: string): string => {
    const [objectId] = columnId.split(columnKey);

    return objectId;
};

export const getUserApprovalsLocalData = (currentUserId: number, approvals: ApprovalDTO[]) => {
    return approvals.reduce((accum, approval) => {
        if (!approval.stages?.length) return accum;

        const stageInProgress = approval.stages.find((stage) => stage.status === ApprovalStageDTOStatus.IN_PROCESS);
        if (!stageInProgress) return accum;

        const currentUser = stageInProgress.approvalUsersDTO?.find((user) => user.principalId === currentUserId);
        if (!currentUser) return accum;

        accum[approval.id.id] = {
            comment: currentUser.comment,
            vote: currentUser.vote,
        };

        return accum;
    }, {});
};

export const filterAttributeTypesByValueTypes = (
    attributeTypes: AttributeType[],
    valueTypes: AttributeValueValueTypeEnum[],
): AttributeType[] => {
    return attributeTypes.filter((attributeType) => {
        if (!attributeType.valueType) return false;

        return valueTypes.includes(attributeType.valueType);
    });
};

export const getElementMessageType = (
    type: NodeTypeEnum | undefined,
    isEdge: boolean,
    isEdgeDefinition: boolean,
): NodeTypeEnum | 'EDGE_DEFENITION' =>
    `${type || (isEdge ? 'EDGE' : 'OBJECT')}${isEdgeDefinition && !isEdge ? '_DEFENITION' : ''}` as
        | NodeTypeEnum
        | 'EDGE_DEFENITION';

import React, { useEffect } from 'react';
import { Alert } from 'antd';
import { ServerForm } from '../../ServerForm/components/ServerForm.component';
import theme from './StartScreen.scss';
import messages from './StartScreen.messages';
import AuthMessages from '../../../services/response.messages';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';
import { GIT_BRANCH, SERVER_REVISION_VERSION, SERVER_VERSION } from '../../../ServerInfo';
import { AppStatus } from '../../../models/app';
import { TStartScreenProps } from '../StartScreen.types';
import electron from '../../../electron';
import { useDispatch, useSelector } from 'react-redux';
import { LocalesService } from '@/services/LocalesService';
import { getCurrentLocale } from '@/selectors/locale.selectors';
import { LocalStorageDaoService } from '@/services/dao/LocalStorageDaoService';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { loginFailed } from '@/actions/authorization.actions';
import { SystemPropertiesSelectors } from '@/selectors/systemProperties.selectors';
import { ExternalLinkBLLService } from '@/services/bll/ExternalLinkBLLService';
import { KERBEROS_SSO_PARAM, KEYCLOAK_PARAM } from '@/utils/consts';

const StartScreen = (props: TStartScreenProps) => {
    const {
        appStatus,
        error,
        availableServers,
        intl,
        dialogRoot,
        header,
        pending,
        onServerEdit,
        onServerAdd,
        onSubmit,
        setErrorMessage,
        keycloakSettings,
        handleReRender,
    } = props;

    const dispatch = useDispatch();
    useEffect(() => {
        if (ExternalLinkBLLService.hasKeycloakParamInUrl(window.location.href) && !keycloakSettings) {
            dispatch(loginFailed(intl.formatMessage(AuthMessages.autoLoginFail)));
        }
    }, []);

    const currentLocale = useSelector(getCurrentLocale);
    const isKerberosAvailable = useSelector(SystemPropertiesSelectors.hasKerberosAuthorizationMethod);
    const greetingText = LocalStorageDaoService.getGreetingText();
    const defaultTitle = intl.formatMessage(messages.title);
    const title = greetingText
        ? LocalesService.internationalStringToString(greetingText, currentLocale) || defaultTitle
        : defaultTitle;

    const waitScreenClassNames = classnames(theme.waitScreen, pending ? theme.displayWaitScreen : '');

    const onKeycloakLogin = () => {
        const url = new URL(window.location.href);
        url.searchParams.set('auth', 'keycloak');
        window.history.replaceState(null, '', url);
        if (ExternalLinkBLLService.hasKerberosParamInUrl(window.location.href)) {
            window.history.replaceState(null, '', window.location.href.replace(KERBEROS_SSO_PARAM, ''));
        }
        handleReRender();
    };

    const onKerberosLogin = () => {
        if (ExternalLinkBLLService.hasKeycloakParamInUrl(window.location.href)) {
            window.history.replaceState(null, '', window.location.href.replace(KEYCLOAK_PARAM, ''));
        }

        if (!ExternalLinkBLLService.hasKerberosParamInUrl(window.location.href)) {
            window.history.replaceState(
                null,
                '',
                window.location.href.replace(window.location.origin, `${window.location.origin}${KERBEROS_SSO_PARAM}`),
            );
            window.history.replaceState(null, '', window.location.href.replace('sso/', 'sso'));
        }

        window.location.reload();
    };

    return (
        <div className={theme.container}>
            <div className={waitScreenClassNames} />
            <div className={theme.header}>{header}</div>
            <div className={theme.body}>
                <div className={theme.titleWrapper}>
                    <div className={theme.title}>{title}</div>
                </div>
                <div className={theme.wrapper}>
                    <div className={theme.header}>
                        {electron && (
                            <div className={theme.subTitle}>
                                <div>{intl.formatMessage(messages.chooseYourServer)}</div>
                                <div>{intl.formatMessage(messages.addNewOne)}</div>
                            </div>
                        )}
                    </div>
                    <div className={theme.form}>
                        <ServerForm
                            onClickServerEdit={onServerEdit}
                            onClickServerAdd={onServerAdd}
                            onLogin={onSubmit}
                            availableServers={availableServers}
                            setErrorMessage={setErrorMessage}
                        />
                        {keycloakSettings ? (
                            <Button
                                dataTest="login-page_keycloak-login-button"
                                visualStyle={{ type: 'text', color: 'white' }}
                                onClick={onKeycloakLogin}
                            >
                                {intl.formatMessage(messages.loginKeycloak)}
                            </Button>
                        ) : null}
                        {isKerberosAvailable ? (
                            <Button
                                dataTest="login-page_kerberos-login-button"
                                visualStyle={{ type: 'text', color: 'white' }}
                                onClick={onKerberosLogin}
                            >
                                {intl.formatMessage(messages.loginKerberos)}
                            </Button>
                        ) : null}
                        {error && <Alert className={theme.alert} type="error" message={error} showIcon />}
                        {appStatus === AppStatus.Kicked && (
                            <Alert
                                className={theme.alert}
                                type="error"
                                message={intl.formatMessage(messages.kicked)}
                                showIcon
                            />
                        )}
                    </div>
                </div>
            </div>
            {dialogRoot}
            <div style={{ margin: '20px' }}>
                <ul>
                    <li>
                        {intl.formatMessage(messages.serverVersion, {
                            SERVER_VERSION: `${SERVER_VERSION}.${SERVER_REVISION_VERSION}-grc`,
                        })}
                    </li>
                    <li>{intl.formatMessage(messages.release, { GIT_BRANCH })}</li>
                </ul>
            </div>
        </div>
    );
};

const IntlComponent = injectIntl(StartScreen);

export { IntlComponent as StartScreen };

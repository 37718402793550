import { Plugin, PluginKey } from '@tiptap/pm/state';
import { Extension } from '@tiptap/core';

type TDropHandlerOptions = {
    /**
     * The onDrop callback that is called when a file is dropped.
     * @param editor the editor instance
     * @param pos drop position
     * @param files the File array including File objects
     * @returns Returns nothing.
     */
    onDrop?: (pos: number, event: DragEvent) => void;
};

export const DropHandler = Extension.create<TDropHandlerOptions>({
    name: 'dropHandler',

    addOptions: () => ({
        onDrop: () => null,
    }),

    addProseMirrorPlugins() {
        const onDrop = this.options.onDrop;

        return [
            new Plugin({
                key: new PluginKey('handleDrop'),
                props: {
                    handleDrop(view, event) {
                        if (!onDrop) {
                            return;
                        }

                        const dropPosition = view.posAtCoords({ left: event.clientX, top: event.clientY });

                        onDrop(dropPosition?.pos || 0, event);

                        return view.focus();
                    },
                },
            }),
        ];
    },
});

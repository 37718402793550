import type { TSubmitModelTypePayload } from '../../../../../../actions/presetSettings/presetSettingsModelType.actions.types';
import type { TWithWindowResizeProps } from '../../../../../UIKit/H.O.C/withWindowResize/withWindowResize.types';
import type { IWorkspaceTabItemModelEditParams, TWorkspaceTab } from '../../../../../../models/tab.types';
import type {
    AttributeType,
    AttributeTypeGroup,
    EdgeType,
    ModelType,
    ModelTypeGroup,
    Symbol,
} from '../../../../../../serverapi/api';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { SymbolSelectors } from '../../../../../../selectors/symbol.selectors';
import messages from './ModelType.messages';
import presetMessages from '../../../messages/Presets.messages';
import { Form, FormInstance, Tabs } from 'antd';
import { ModelTypeGeneralTab } from './ModelTypeGeneralTab/ModelTypeGeneralTab.component';
import { SymbolsTab } from './SymbolsTab/SymbolsTab.component';
import { EdgesTab } from './EdgesTab/EdgesTab.component';
import { AttributesTab } from '../AttributeType/AttributesTab.component';
import { FooterButtons } from '../../../../../Footer/FooterButtons.component';
import footerMessages from '../../../../../Footer/FooterButtons.messages';
import { AttributeTypeSelectors } from '../../../../../../selectors/attributeType.selectors';
import { EdgeTypeSelectors } from '../../../../../../selectors/edgeType.selectors';
import { workspaceRemoveTabByNodeId } from '../../../../../../actions/tabs.actions';
import { submitModelType } from '../../../../../../actions/presetSettings/presetSettingsModelType.actions';
import { WorkspaceTabDataSelectors } from '../../../../../../selectors/workspaceTabData.selectors';
import {
    addAttributeTypes,
    changeModelTypeGroup,
    removeAttributeTypes,
    removeAttributeTypesGroups,
} from '../../../../../../actions/workspaceTab/editModelTypeWorkspaceTab.actions';
import { withWindowResize } from '../../../../../UIKit/H.O.C/withWindowResize/withWindowResize.hoc';
import { checkPoolsHaveLanes } from './utils/modelTypes.utils';
import theme from '../Presets.scss';
import { showNotificationByType } from '../../../../../../actions/notification.actions';
import { NotificationType } from '../../../../../../models/notificationType';
import { ModelTypeGroupSelectors } from '../../../../../../selectors/modelTypeGroup.selectors';
import { LocalesService } from '../../../../../../services/LocalesService';
import { createButtonsCompositeDataTestId } from '../util/createButtonsCompositeDataTestId.utils';
import type { Tab } from 'rc-tabs/lib/interface';
import { TButtonProps } from '@/modules/UIKit/components/Button/Button.types';

type TModelTypeEditorProps = TWithWindowResizeProps & {
    tab: TWorkspaceTab;
};

const ModelTypeEditorComponent: FC<TModelTypeEditorProps> = (props) => {
    const params = props.tab.params as IWorkspaceTabItemModelEditParams;
    const { serverNode, preset, createMode, modelTypeId } = params;
    const { serverId } = serverNode.nodeId;
    const presetId = preset.id;

    const formRef = useRef<FormInstance>(null);
    const intl = useIntl();
    const dispatch = useDispatch();

    const symbols: Symbol[] = useSelector(SymbolSelectors.byServerIdPresetId(serverId, preset.id)) || [];
    const modelType: ModelType = useSelector(
        WorkspaceTabDataSelectors.getEditModelType(presetId, serverId, modelTypeId),
    );
    const modelTypeGroups: ModelTypeGroup[] = useSelector(
        ModelTypeGroupSelectors.byPresetIdExcludeDeleted({
            serverId,
            presetId: preset.id,
        }),
    );

    // Символы модели заменются на актуальные символы из state.symbol
    const modelTypeSymbols: Symbol[] = SymbolSelectors.chooseModelTypeSymbols(modelType?.symbols || [], symbols) || [];

    const availableAttributeTypes: AttributeType[] =
        useSelector(AttributeTypeSelectors.allInPreset(serverId, preset.id)) || [];

    const availableEdgeTypeObj = useSelector(EdgeTypeSelectors.byPresetId({ serverId, presetId: preset.id }));

    const availableEdgeType: EdgeType[] = Object.values(availableEdgeTypeObj?.byId || {});

    const [saveDisabled, setSaveDisabled] = useState<boolean>(!modelType?.modelTypeGroup);

    useEffect(() => {
        if (!modelType) {
            dispatch(showNotificationByType(NotificationType.MODEL_TO_EDIT_NOT_FOUND));
        }
    }, [modelType]);

    const poolsHaveLanes: boolean = checkPoolsHaveLanes(modelTypeSymbols);

    const onChangeModelTypeGroup = useCallback((modelTypeGroup: ModelTypeGroup) => {
        dispatch(changeModelTypeGroup({ serverId, presetId, modelTypeId, modelTypeGroup }));

        setSaveDisabled(!modelTypeGroup);
    }, []);

    const sendAttributeTypes = useCallback((attributeTypes: AttributeType[]) => {
        dispatch(addAttributeTypes({ serverId, presetId, modelTypeId, attributeTypes }));
    }, []);

    const deleteAttributeTypes = useCallback(
        (attributeTypes: AttributeType[]) => {
            dispatch(removeAttributeTypes({ serverId, presetId, modelTypeId, attributeTypes }));
        },
        [modelType],
    );

    const deleteAttributeTypeGroups = useCallback(
        (attributeTypeGroups: AttributeTypeGroup[]) => {
            dispatch(removeAttributeTypesGroups({ serverId, presetId, modelTypeId, attributeTypeGroups }));
        },
        [modelType],
    );

    const deleteQuestion: string = intl.formatMessage(presetMessages.deleteModel, {
        name: LocalesService.internationalStringToString(modelType.multilingualName),
    });

    const buttons: TButtonProps[] = [
        {
            children: intl.formatMessage(footerMessages.cancel),
            onClick: () => dispatch(workspaceRemoveTabByNodeId(props.tab.nodeId)),
            dataTest: 'methodology-model-type-editor_cancel-btn',
            size: 'large',
        },
        {
            children: createMode ? intl.formatMessage(footerMessages.create) : intl.formatMessage(footerMessages.save),
            visualStyle: 'primary',
            disabled: !poolsHaveLanes || saveDisabled,
            tooltip: !poolsHaveLanes ? intl.formatMessage(messages.addLanes) : '',
            onClick: () => {
                const form = formRef.current;
                if (form) {
                    form.validateFields()
                        .then(() => {
                            const payload: TSubmitModelTypePayload = {
                                serverId,
                                modelTypes: [{ ...modelType, symbols: modelTypeSymbols }],
                                presetId: preset.id,
                                createMode,
                                needTabClose: true,
                                tabNodeId: props.tab.nodeId,
                            };
                            dispatch(submitModelType(payload));
                        })
                        .catch(() => undefined);
                }
            },
            dataTest: 'methodology-model-type-editor_save-btn',
            size: 'large',
        },
    ];

    const tabs: Tab[] = [
        {
            label: <span>{intl.formatMessage(messages.generalSettings)}</span>,
            key: 'GeneralSettings',
            children: (
                <div className={theme.tabContent}>
                    <Form ref={formRef} layout="vertical">
                        <ModelTypeGeneralTab
                            modelType={modelType}
                            modelTypeGroups={modelTypeGroups}
                            createMode={createMode}
                            onChangeModelTypeGroup={onChangeModelTypeGroup}
                            generalForm={formRef.current!}
                            serverId={serverId}
                        />
                    </Form>
                    <FooterButtons
                        deleteQuestion={deleteQuestion}
                        buttons={createButtonsCompositeDataTestId(buttons, 'general-settings_tab')}
                    />
                </div>
            ),
            disabled: createMode,
        },
        {
            label: <span data-test="model_types_editor-symbols_tab">{intl.formatMessage(messages.symbols)}</span>,
            key: 'ModelTypeSymbols',
            children: (
                <div className={theme.tabContent}>
                    <SymbolsTab
                        availableAttributeTypes={availableAttributeTypes}
                        modelTypeSymbols={modelTypeSymbols}
                        modelType={modelType}
                        symbols={symbols}
                        serverId={serverId}
                        preset={preset}
                        serverNode={serverNode}
                    />
                    <FooterButtons
                        deleteQuestion={deleteQuestion}
                        buttons={createButtonsCompositeDataTestId(buttons, 'symbols_tab')}
                    />
                </div>
            ),
            disabled: createMode,
        },
        {
            label: <span data-test="model-types-editor_edges-tab">{intl.formatMessage(messages.edgesOnModel)}</span>,
            key: 'ModelTypeEdges',
            children: (
                <div className={theme.tabContent}>
                    <EdgesTab
                        availableAttributeTypes={availableAttributeTypes}
                        serverId={serverId}
                        modelType={modelType}
                        availableSymbols={modelTypeSymbols}
                        modelTypeEdges={modelType.modelEdgeDefinitions}
                        availableEdgeType={availableEdgeType}
                        serverNode={serverNode}
                    />
                    <FooterButtons
                        deleteQuestion={deleteQuestion}
                        buttons={createButtonsCompositeDataTestId(buttons, 'edges_tab')}
                    />
                </div>
            ),
            disabled: createMode,
        },
        {
            label: <span>{intl.formatMessage(messages.attributes)}</span>,
            key: 'ModelTypeAttributes',
            children: (
                <div className={theme.tabContent}>
                    <AttributesTab
                        attributeTypes={modelType.attributes || []}
                        availableAttributeTypes={availableAttributeTypes}
                        addAttributeTypes={sendAttributeTypes}
                        deleteAttributeTypes={deleteAttributeTypes}
                        deleteAttributeTypeGroups={deleteAttributeTypeGroups}
                    />
                    <FooterButtons
                        deleteQuestion={deleteQuestion}
                        buttons={createButtonsCompositeDataTestId(buttons, 'attributes_tab')}
                    />
                </div>
            ),
            disabled: createMode,
        },
    ];

    return (
        <div className={theme.container}>
            {modelType ? (
                <>
                    <span className={theme.navigationTitle}>{`${preset.name} > ${intl.formatMessage(
                        messages.typeModel,
                    )} > ${modelType.name}`}</span>
                    <Tabs className={theme.tabs} tabPosition="left" defaultActiveKey="GeneralSettings" items={tabs} />
                </>
            ) : (
                <></>
            )}
        </div>
    );
};

export default withWindowResize(ModelTypeEditorComponent);

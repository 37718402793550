import React from 'react';
import { Input } from 'antd';
import theme from './styles/AssistantInterfacePanel.scss';
import closeIconBlack from '../../resources/icons/closeIcon.svg';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { Icon } from '@/modules/UIKit';
import { useDispatch, useSelector } from 'react-redux';
import { getSearchResult } from './actions/assistantInterface.actions';
import classNames from 'classnames';
import { InternationalString, NodeId } from '@/serverapi/api';
import messages from './/messages/AssistantInterfacePanel.messages';
import { AssistantInterfaceSearchSelectors } from './selectors/assistantInterfaceSearch.selectors';
import { TreeSelectors } from '@/selectors/tree.selectors';
import { TreeNode } from '@/models/tree.types';
import { useIntl } from 'react-intl';
import { Spinner } from '@/modules/Spinner/Spinner.component';
import { NAVIGATOR_STRUCTURE } from '@/utils/consts';
import { moveToDirectAction } from '@/actions/editor.actions';
import { LocalesService } from '@/services/LocalesService';
import { TreeItemType } from '@/modules/Tree/models/tree';
import { TSearchDataListItem } from './reducers/searchAssistantInterface.reducer.types';

type TAssistantInterfacePanel = {
    setIsVisibleAipPannel: (value: boolean) => void;
};

export const AssistantInterfacePanel = ({ setIsVisibleAipPannel }: TAssistantInterfacePanel) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const treeStructure: TreeNode[] = useSelector(TreeSelectors.treeStructure);
    const treeStructureChildren: TreeNode[] = treeStructure?.[0]?.children || [];
    // Проверяем, что существует хотя бы одна база данных
    const nodeId: NodeId | undefined = treeStructureChildren.length > 0 ? treeStructureChildren[0].nodeId : undefined;

    // Если нет ни одной БД не открываем панель
    if (!nodeId) {
        return null;
    }

    // Поиск по nodeID первой БД
    const searchData: TSearchDataListItem[] = useSelector(AssistantInterfaceSearchSelectors.getSearchResult(nodeId.id));
    const isLoading: boolean = useSelector(AssistantInterfaceSearchSelectors.isLoading(nodeId.id));
    const [inputValue, setInputValue] = React.useState<string>('');

    const handleSearch = () => {
        setInputValue('');
        dispatch(getSearchResult(inputValue, [], 'ALL_INCLUDE_DELETED', [], nodeId));
    };

    const handleItemClick = (nodeId: NodeId, name: InternationalString, type: TreeItemType) => {
        const treeNode: TreeNode = {
            hasChildren: false,
            nodeId,
            name: LocalesService.internationalStringToString(name),
            multilingualName: name,
            type,
            countChildren: 0,
        };
        // (переход в дереве), событие потом можно заменить
        dispatch(moveToDirectAction(treeNode, NAVIGATOR_STRUCTURE));
    };

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setInputValue(e.target.value);
    };
    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSearch();
        }
    };
    const handleClosePanel = () => {
        setIsVisibleAipPannel(false);
    };

    return (
        <div className={theme.aipPannel}>
            <div className={theme.resultBlock}>
                <div className={theme.close}>
                    <Icon onClick={handleClosePanel} spriteSymbol={closeIconBlack} />
                </div>
                <div className={theme.header}>{intl.formatMessage(messages.searchResult)}</div>
                {
                    <Spinner loading={isLoading}>
                        <div className={theme.text}>
                            <ul className={theme.resultList}>
                                {searchData.map((item) => {
                                    const itemName: string = LocalesService.internationalStringToString(item.multilingualName);
                                    return (
                                        <li
                                            key={item.nodeId.id}
                                            onClick={() =>
                                                handleItemClick(item.nodeId, item.multilingualName, item.type)
                                            }
                                            // изменение цвета выводимого текста
                                            className={classNames(theme.resultItem, { [theme.error]: item.deleted })}
                                            title={itemName}
                                        >
                                            {itemName}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </Spinner>
                }
            </div>
            <div className={theme.resultsFooter}>
                {intl.formatMessage(messages.resultsCount)} {searchData.length} {intl.formatMessage(messages.results)}
            </div>
            <div className={theme.searchBlock}>
                <Input.TextArea
                    onKeyDown={handleKeyDown}
                    autoFocus
                    placeholder={intl.formatMessage(messages.placeholder)}
                    className={theme.textArea}
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    onChange={handleChange}
                    value={inputValue}
                    bordered={true}
                />
                <div className={theme.searchBtn}>
                    <Button visualStyle="primary" size="small" onClick={handleSearch}>
                        {intl.formatMessage(messages.search)}
                    </Button>
                </div>
            </div>
        </div>
    );
};

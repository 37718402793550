import { defineMessages } from 'react-intl';

export default defineMessages({
    N: {
        id: 'Safety.audit.table.N',
        defaultMessage: '№',
    },
    time: {
        id: 'Safety.audit.table.time',
        defaultMessage: 'Дата и время',
    },
    login: {
        id: 'Safety.audit.table.login',
        defaultMessage: 'Логин',
    },
    action: {
        id: 'Safety.audit.table.action',
        defaultMessage: 'Тип действия',
    },
    tradeSecretPersonalData: {
        id: 'Safety.audit.table.tradeSecretPersonalData',
        defaultMessage: 'КТ/ПД',
    },
    tradeSecret: {
        id: 'Safety.audit.table.tradeSecret',
        defaultMessage: 'КТ',
    },
    personalData: {
        id: 'Safety.audit.table.personalData',
        defaultMessage: 'ПД',
    },
    exceptionType: {
        id: 'Safety.audit.table.exceptionType',
        defaultMessage: 'Тип ошибки',
    },
    exceptionMessage: {
        id: 'Safety.audit.table.exceptionMessage',
        defaultMessage: '\nСообщение об ошибке: \n',
    },
    objectName: {
        id: 'Safety.audit.table.objectName',
        defaultMessage: 'Объект действия',
    },
    elementType: {
        id: 'Safety.audit.table.elementType',
        defaultMessage: 'Тип элемента',
    },
    loadBtn: {
        id: 'Safety.audit.loadBtn',
        defaultMessage: 'Обновить',
    },
    downloadBtn: {
        id: 'Safety.audit.downloadBtn',
        defaultMessage: 'Сохранить в CSV',
    },
    byType: {
        id: 'Safety.audit.sortDropdown.byType',
        defaultMessage: 'По типу события',
    },
    byAccess: {
        id: 'Safety.audit.sortDropdown.byAccess',
        defaultMessage: 'По доступу',
    },
    byUser: {
        id: 'Safety.audit.sortDropdown.byUser',
        defaultMessage: 'По пользователю',
    },
    today: {
        id: 'Safety.audit.datepicker.today',
        defaultMessage: 'Сегодня',
    },
    currentMonth: {
        id: 'Safety.audit.datepicker.currentMonth',
        defaultMessage: 'Текущий месяц',
    },
    startDate: {
        id: 'Safety.audit.datepicker.startDate',
        defaultMessage: 'Начальная дата',
    },
    endDate: {
        id: 'Safety.audit.datepicker.endDate',
        defaultMessage: 'Конечная дата',
    },
    connectIp: {
        id: 'Safety.audit.connectIp',
        defaultMessage: 'IP-адрес подключения',
    },
    WRITE_OBJECT_ACL_DETAILED: {
        id: 'WRITE_OBJECT_ACL_DETAILED',
        defaultMessage:
            'Пользователь {adminName} изменил права доступа {userName}:\nПрежнее значение - {oldValue}\nНовое значение - {newValue}\n',
    },
    CHANGE_PRINCIPAL_NAME: {
        id: 'Safety.CHANGE_PRINCIPAL_NAME',
        defaultMessage: 'Имя пользователя:\n\t прежнее значение - {oldFirstName},\n\t новое значение - {newFirstName}',
    },
    CHANGE_PRINCIPAL_LAST_NAME: {
        id: 'Safety.CHANGE_PRINCIPAL_SECOND_NAME',
        defaultMessage:
            'Фамилия пользователя:\n\t прежнее значение - {oldLastName},\n\t новое значение - {newLastName}',
    },
    CREATE_PRINCIPAL: {
        id: 'Safety.CHANGE_PRINCIPAL',
        defaultMessage:
            'Имя пользователя - {firstName}\n' +
            'Фамилия пользователя - {lastName}\n' +
            'Отчество пользователя - {middleName}\n' +
            'Почта пользователя - {mail}\n' +
            'Департамент пользователя - {department}\n' +
            'Должность пользователя - {position}\n' +
            'Компания - {company}\n' +
            'Заблокирован - {blocked}',
    },
    CHANGE_PRINCIPAL_MIDDLE_NAME: {
        id: 'Safety.CHANGE_PRINCIPAL_SUR_NAME',
        defaultMessage:
            'Отчество пользователя:\n\t прежнее значение - {oldMiddleName}, \n\tновое значение - {newMiddleName}',
    },
    CHANGE_PRINCIPAL_MAIL: {
        id: 'Safety.CHANGE_PRINCIPAL_MAIL',
        defaultMessage: 'Почта пользователя:\n\t прежнее значение - {oldMail},\n\t новое значение - {newMail}',
    },
    CHANGE_PRINCIPAL_DEPARTMENT: {
        id: 'Safety.CHANGE_PRINCIPAL_DEPARTMENT',
        defaultMessage:
            'Департамент пользователя: \n\tпрежнее значение - {oldDepartment},\n\t новое значение - {newDepartment}',
    },
    CHANGE_PRINCIPAL_POSITION: {
        id: 'Safety.CHANGE_PRINCIPAL_POSITION',
        defaultMessage:
            'Должность пользователя:\n\t прежнее значение - {oldPosition}, \n\tновое значение - {newPosition}',
    },
    CHANGE_PRINCIPAL_COMPANY: {
        id: 'Safety.CHANGE_PRINCIPAL_COMPANY',
        defaultMessage: 'Компания:\n\t прежнее значение - {oldCompany},\n\t новое значение - {newCompany}',
    },
    CHANGE_PRINCIPAL_BLOCKED: {
        id: 'Safety.CHANGE_PRINCIPAL_BLOCKED',
        defaultMessage: 'Заблокирован:\n\t прежнее значение - {oldBlocked}, \n\tновое значение - {newBlocked}',
    },
    CHANGE_PRINCIPAL_GROUP: {
        id: 'Safety.CHANGE_PRINCIPAL_GROUP',
        defaultMessage: 'Группы пользователя: \n\tпрежнее значение - {oldGroups} \n\tновое значение - {newGroups}\n',
    },
    CHANGE_PRINCIPAL_LICENSE: {
        id: 'Safety.CHANGE_PRINCIPAL_LICENSE',
        defaultMessage:
            'Лицензии пользователя: \n\tпрежнее значение - {oldLicenses} \n\tновое значение - {newLicenses}\n',
    },
    CHANGE_PRINCIPAL_ACCESS: {
        id: 'Safety.CHANGE_PRINCIPAL_ACCESS',
        defaultMessage:
            'Функциональные права пользователя: \n\tпрежнее значение - {oldLicenses} \n\tновое значение - {newLicenses}\n',
    },
    RENAME_TREE_NODE: {
        id: 'Safety.RENAME_TREE_NODE',
        defaultMessage: 'Переименование узла: \n\tпрежнее значение - {oldName} \n\tновое значение - {newName}\n',
    },

    DELETE_PRINCIPAL: {
        id: 'Safety.DELETE_PRINCIPAL',
        defaultMessage: 'Удаленный пользователь - {login}',
    },
    presetId: {
        id: 'Safety.presetId',
        defaultMessage: 'Идентификатор пресета: ',
    },
    value: {
        id: 'newValue',
        defaultMessage: 'С={create}, Ч={read}, И={update}, Уд={delete}, Уп={control}',
    },
    missing: {
        id: 'missing',
        defaultMessage: 'Отсутствует',
    },
    uncover: {
        id: 'Action.audit.uncover',
        defaultMessage: 'раскрыть',
    },
    path: {
        id: 'path',
        defaultMessage: '\nПуть: ',
    },
    FOLDER: {
        id: 'Safety.audit.FOLDER',
        defaultMessage: 'Папка',
    },
    MODEL: {
        id: 'Safety.audit.MODEL',
        defaultMessage: 'Модель',
    },
    OBJECT: {
        id: 'Safety.audit.OBJECT',
        defaultMessage: 'Объект',
    },
    DB: {
        id: 'Safety.audit.DB',
        defaultMessage: 'База данных',
    },
    SCRIPT: {
        id: 'Safety.audit.SCRIPT',
        defaultMessage: 'Скрипт',
    },
    SCRIPT_FOLDER: {
        id: 'Safety.audit.SCRIPT_FOLDER',
        defaultMessage: 'Папка для скриптов',
    },
    WIKI: {
        id: 'Safety.audit.WIKI',
        defaultMessage: 'Wiki',
    },
    MATRIX: {
        id: 'Safety.audit.MATRIX',
        defaultMessage: 'Матрица',
    },
    DASHBOARD: {
        id: 'Safety.audit.DASHBOARD',
        defaultMessage: 'Дашборд',
    },
    EDGE: {
        id: 'Safety.audit.EDGE',
        defaultMessage: 'Связь',
    },
    SPREADSHEET: {
        id: 'Safety.audit.SPREADSHEET',
        defaultMessage: 'Таблица',
    },
    SCRIPT_RESULT: {
        id: 'Safety.audit.SCRIPT_RESULT',
        defaultMessage: 'Результаты скрипта',
    },
    SIMULATION: {
        id: 'Safety.audit.SIMULATION',
        defaultMessage: 'Имитационное моделирование',
    },
    USER: {
        id: 'Safety.audit.USER',
        defaultMessage: 'Пользователь',
    },
    GROUP: {
        id: 'Safety.audit.GROUP',
        defaultMessage: 'Группа',
    },
    FILE: {
        id: 'Safety.audit.FILE',
        defaultMessage: 'Файл',
    },
    FILE_FOLDER: {
        id: 'Safety.audit.FILE_FOLDER',
        defaultMessage: 'Файловая директория',
    },
});

import type { Node, NodeType } from '@tiptap/pm/model';
import type { Editor } from '@tiptap/core';
import type {
    TGetTableOfContentIndexFunction,
    TGetTableOfContentLevelFunction,
    THandleTableOfContentUpdateFunction,
    THeadline,
    TTableOfContentData,
    TTableOfContentDataItem,
    TTableOfContentsStorage,
} from './table-of-contents.types';

type TAnchorTypes = (string | NodeType)[];

type TOptions = {
    editor: Editor;
    anchorTypes?: TAnchorTypes;
    storage: TTableOfContentsStorage;
    onUpdate: THandleTableOfContentUpdateFunction;
    getIndexFn: TGetTableOfContentIndexFunction;
    getLevelFn: TGetTableOfContentLevelFunction;
};

// TODO нужно исправить логику работы
// export const getLastHeadingOnLevel: TGetTableOfContentLevelFunction = (headline, previousItems) => {
//     let o = headline.filter((t) => t.level === previousItems).pop();

//     if (0 !== previousItems) return o || (o = getLastHeadingOnLevel(headline, previousItems - 1)), o;
// };

export const getReducedLevel: TGetTableOfContentLevelFunction = (headline, previousItems, levels) => {
    const headLevel: number = levels.reduce((acc, level) => (!acc || level < acc ? level : acc));

    return headline.node.attrs.level - headLevel + 1;
};

export const getHeadlineLevel: TGetTableOfContentLevelFunction = (headline, previousItems) => {
    const lastItem: TTableOfContentDataItem | undefined = previousItems.at(-1);
    const baseLevel: number = lastItem?.originalLevel || 1;

    if (headline.node.attrs.level > baseLevel) {
        return (lastItem?.level || 1) + 1;
    }

    if (headline.node.attrs.level < baseLevel) {
        const headItem: TTableOfContentDataItem | undefined = previousItems.findLast(
            (item) => item.originalLevel <= headline.node.attrs.level,
        );

        return headItem?.level || 1;
    }

    return lastItem?.level || 1;
};

export const getLinearIndexes: TGetTableOfContentIndexFunction = (headline, previousItems) => {
    const lastItem: TTableOfContentDataItem | undefined = previousItems.at(-1);
    const lastItemIndex: number | undefined = lastItem?.itemIndex ? Number(lastItem.itemIndex) : undefined;

    const index: number = lastItem ? (lastItemIndex || 1) + 1 : 1;

    return `${index}.`;
};

export const getHierarchicalIndexes: TGetTableOfContentIndexFunction = (headline, previousItems, currentLevel) => {
    const level: number = currentLevel || headline.node.attrs.level || 1;
    const lastItem: TTableOfContentDataItem | undefined = previousItems.filter((item) => item.level <= level).at(-1);
    const lastItemIndex: number | undefined = lastItem?.itemIndex ? Number(lastItem.itemIndex) : undefined;

    const index: number = lastItem?.level === level ? (lastItemIndex || 1) + 1 : 1;

    return `${index}.`;
};

export const getFullHierarchicalIndexes: TGetTableOfContentIndexFunction = (headline, previousItems, currentLevel) => {
    const level: number = currentLevel || headline.node.attrs.level || 1;
    const lastItem: TTableOfContentDataItem | undefined = previousItems.filter((item) => item.level <= level).at(-1);
    const lastItemIndex: number | undefined = lastItem?.itemIndex
        ? Number(lastItem?.itemIndex.split('.').at(-2))
        : undefined;

    const index: number = lastItem?.level === level ? (lastItemIndex || 1) + 1 : 1;
    const parentItem: TTableOfContentDataItem | undefined = previousItems.findLast(
        (item) => item.level + 1 === currentLevel,
    );

    return parentItem ? `${parentItem.itemIndex}${index}.` : `${index}.`;
};

export const getData = (
    data: TTableOfContentData,
    options: Omit<TOptions, 'getIndexFn' | 'getLevelFn'>,
): TTableOfContentData => {
    const { editor, anchorTypes, storage, onUpdate } = options;
    const headlines: THeadline[] = [];
    const ids: string[] = [];
    let tocId = null;

    editor.state.doc.descendants((node: Node, pos: number) => {
        if (null === anchorTypes || void 0 === anchorTypes ? void 0 : anchorTypes.includes(node.type.name)) {
            headlines.push({ node, pos });
        }
    });

    headlines.forEach(({ node, pos }) => {
        const domNode: HTMLElement = editor.view.domAtPos(pos + 1).node as HTMLElement;

        if (storage.scrollPosition >= domNode.offsetTop) {
            tocId = node.attrs['data-toc-id'];

            ids.push(node.attrs['data-toc-id']);
        }
    });

    data = data.map((item) => ({
        ...item,
        isActive: item.id === tocId,
        isScrolledOver: ids.includes(item.id),
    }));

    if (onUpdate) {
        onUpdate(data, 0 === storage.content.length);
    }

    return data;
};

export const updateData = (options: TOptions) => {
    const { editor, anchorTypes, storage, onUpdate, getLevelFn, getIndexFn } = options;
    const headlines: THeadline[] = [];
    const anchors: (HTMLElement | HTMLHeadingElement)[] = [];
    let data: TTableOfContentData = [];

    editor.state.doc.descendants((node, pos) => {
        if (null === anchorTypes || void 0 === anchorTypes ? void 0 : anchorTypes.includes(node.type.name)) {
            headlines.push({ node, pos });
        }
    });

    const levels = [...new Set(headlines.map((item) => item.node.attrs.level))];

    headlines.forEach(({ node, pos }, index) => {
        if (0 === node.textContent.length) {
            return;
        }

        const domNode = editor.view.domAtPos(pos + 1).node as HTMLElement;
        const isScrolledOver = storage.scrollPosition >= domNode.offsetTop;

        anchors.push(domNode);

        const originalLevel = node.attrs.level;
        const previousHeadline = headlines[index - 1];
        const currentLevel = getLevelFn({ node, pos }, data, levels);
        const currentIndex = getIndexFn({ node, pos }, data, currentLevel);

        data = [
            ...data,
            {
                itemIndex: currentIndex,
                id: node.attrs['data-toc-id'],
                originalLevel,
                level: currentLevel,
                textContent: node.textContent,
                pos,
                editor,
                isActive: false,
                isScrolledOver: previousHeadline ? false : isScrolledOver,
                node: node,
                dom: domNode as HTMLHeadingElement,
            },
        ];
    });

    data = getData(data, options);

    if (onUpdate) {
        onUpdate(data, 0 === storage.content.length);
    }

    storage.anchors = anchors;
    storage.content = data;
    editor.state.tr.setMeta('toc', data);
    editor.view.dispatch(editor.state.tr);
};

import { defineMessages } from 'react-intl';

export default defineMessages({
    resultsCount: {
        id: 'AssistantInterfacePanel.resultsCount',
        defaultMessage: 'Всего найдено',
    },
    results: {
        id: 'AssistantInterfacePanel.results',
        defaultMessage: 'результатов',
    },
    placeholder: {
        id: 'AssistantInterfacePanel.placeholder',
        defaultMessage: 'Введите запрос и нажмите ENTER'
    },
    search: {
        id: 'AssistantInterfacePanel.search',
        defaultMessage: 'Поиск'
    },
    searchResult: {
        id: 'AssistantInterfacePanel.searchResult',
        defaultMessage: 'Результаты поиска:'
    },
});



import { ISearchRuleWithValueId } from '@/modules/Search/AttributeFilter/AttributeFilter.types';
import {
    GET_SEARCH_RESULT,
    OPEN_MODEL_ON_CANVAS,
    OPEN_SEARCH_PATH_DIALOG,
    RESET_SEARCH_DATA,
    SET_SEARCH_DATA,
    SET_SEARCH_PATH_ELEMENT,
    SET_FILTER_DATA,
} from '../actionsTypes/search.actionTypes';
import { NodeId, SearchRequestNodeTypesEnum, SearchRequestSearchVisibilityEnum } from '../serverapi/api';
import {
    TGetSearchResultAction,
    TOpenModelOnCanvasAction,
    TOpenModelOnCanvasPayload,
    TOpenSearchPathDialogAction,
    TResetSearchDataAction,
    TSearchDataAction,
    TSearchDataPayload,
    TSetSearchPathElementAction,
    TFilterDataPayload,
    TFilterDataAction,
} from './search.actions.types';

export const openModelOnCanvas = (payload: TOpenModelOnCanvasPayload): TOpenModelOnCanvasAction => ({
    type: OPEN_MODEL_ON_CANVAS,
    payload,
});

export const setSearchData = (payload: TSearchDataPayload): TSearchDataAction => ({
    type: SET_SEARCH_DATA,
    payload,
});

export const setFilterData = (payload: TFilterDataPayload): TFilterDataAction => ({
    type: SET_FILTER_DATA,
    payload,
});

export const resetSearchData = (nodeId: NodeId): TResetSearchDataAction => ({
    type: RESET_SEARCH_DATA,
    payload: { nodeId },
});

export const openSearchPathDialog = (): TOpenSearchPathDialogAction => ({
    type: OPEN_SEARCH_PATH_DIALOG,
});

export const setSearchPathElement = (nodeId: NodeId, type: string, name: string): TSetSearchPathElementAction => ({
    type: SET_SEARCH_PATH_ELEMENT,
    payload: {
        nodeId,
        type,
        name,
    },
});

export const getSearchResult = (
    searchText: string,
    searchRules: ISearchRuleWithValueId[],
    searchVisibility: SearchRequestSearchVisibilityEnum,
    searchNodeTypes: SearchRequestNodeTypesEnum[],
): TGetSearchResultAction => ({
    type: GET_SEARCH_RESULT,
    payload: { searchText, searchRules, searchVisibility, searchNodeTypes },
});

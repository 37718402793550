import { TNodeState } from '@/reducers/entities/TNodeState';
import { MatrixNode } from '@/serverapi/api';
import { TReducer } from '@/utils/types';
import {
    CHANGE_MATRIX_NODE_PROPERTIES,
    MATRIX_REQUEST_SUCCESS,
    MATRIX_SAVE_REQUEST_SUCCESS,
    UPDATE_MATRIX_DATA,
} from '../actionTypes/matrix.actionTypes';
import { TMatrixState } from './matrix.reducer.types';

const INITIAL_MATRIX_STATE: TMatrixState = new TNodeState();

export const matrixReducer: TReducer<TMatrixState> = (state = INITIAL_MATRIX_STATE, action) => {
    switch (action.type) {
        case MATRIX_SAVE_REQUEST_SUCCESS:
        case MATRIX_REQUEST_SUCCESS: {
            const {
                payload: {
                    matrix: { nodeId, version },
                },
            } = action;

            const existMatrix: MatrixNode | undefined = state.byNodeId.get(nodeId);
            if (existMatrix && version) {
                return state.set(nodeId, { ...existMatrix, version });
            }

            return state;
        }

        case CHANGE_MATRIX_NODE_PROPERTIES: {
            const {
                payload: {
                    matrix,
                    matrix: { nodeId },
                },
            } = action;
            const existMatrix: MatrixNode | undefined = state.byNodeId.get(nodeId);
            if (existMatrix) {
                return state.set(nodeId, { ...existMatrix, ...matrix });
            }

            return state.set(nodeId, { ...matrix });
        }

        case UPDATE_MATRIX_DATA: {
            const {
                payload: { nodeId, data },
            } = action;
            const existMatrix: MatrixNode | undefined = state.byNodeId.get(nodeId);
            if (existMatrix && data) {
                return state.set(nodeId, { ...existMatrix, content: data });
            }

            return state;
        }

        default:
            return state;
    }
};

import { getOrigin, replaceLastSlash } from '@/utils/url.utils';
import { ApiBundle } from '../api/api-bundle';
import { ReportType } from '@/serverapi/api';

export class ReportDaoService {
    private static getApi() {
        const hostName = replaceLastSlash(getOrigin());
        const bundle: ApiBundle = new ApiBundle(hostName);

        return bundle;
    }

    public static async createReportType(requestBody: { body: ReportType }) {
        const api = this.getApi();
        await api.reportType.create({ body: requestBody.body });
    }

    public static async saveReportType(requestBody: { body: ReportType }) {
        const api = this.getApi();
        await api.reportType.save({ body: requestBody.body });
    }

    public static async deleteReportType(presetId: string, id: string) {
        const api = this.getApi();
        await api.reportType._delete({ presetId, id });
    }
}

import type { EditorView } from '@tiptap/pm/view';
import { Node, mergeAttributes } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { isLeafNodeEvent } from '../../common/helpers';
import { OBJECT_NODE_NAME } from './constants';

type TObjectOptions = {
    HTMLAttributes: Record<string, any>;
    onObjectClick?: (objectId: string) => void;
};

type TObjectClickProps = {
    extensionName: string;
    onObjectClick?: (objectId: string) => void;
};

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        [OBJECT_NODE_NAME]: {
            /**
             * Insert an object node
             */
            insertObject: (objectId: string, objectName: string) => ReturnType;
        };
    }
}

const getClickHandler =
    ({ extensionName, onObjectClick }: TObjectClickProps) =>
    (view: EditorView, pos: number, event: MouseEvent) => {
        const target = event.target as HTMLElement;
        const position = view.posAtDOM(target, 0);
        const node = view.state.doc.nodeAt(position);

        if (node?.type?.name === extensionName && isLeafNodeEvent(view, event, position)) {
            onObjectClick?.(node.attrs.objectId);
        }
    };

export const Object = Node.create<TObjectOptions>({
    name: OBJECT_NODE_NAME,

    group: 'inline',

    inline: true,

    selectable: false,

    atom: true,

    addOptions() {
        return {
            HTMLAttributes: {},
        };
    },

    addAttributes() {
        return {
            objectId: {
                default: null,
                parseHTML: (element) => {
                    return element.getAttribute('object-id');
                },
                renderHTML: (attributes) => {
                    if (!attributes.objectId) {
                        return {};
                    }

                    return {
                        'object-id': attributes.objectId,
                    };
                },
            },
            objectName: {
                default: null,
                parseHTML: (element) => element.getAttribute('object-name'),
                renderHTML: (attributes) => {
                    if (!attributes.objectName) {
                        return {};
                    }

                    return {
                        'object-name': attributes.objectName,
                    };
                },
            },
        };
    },

    parseHTML() {
        return [{ tag: 'a[object-id]' }];
    },

    renderText({ node }) {
        return node.attrs.objectName;
    },

    renderHTML({ node, HTMLAttributes }) {
        return ['a', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), node.attrs.objectName];
    },

    addCommands() {
        return {
            insertObject:
                (objectId: string, objectName: string) =>
                ({ state, chain }) => {
                    const attributes = { objectId, objectName };
                    const { $from: from, $to: to } = state.selection;
                    const range = { from: from.pos, to: to.pos };

                    return chain()
                        .focus()
                        .insertContentAt(range, [
                            {
                                type: this.name,
                                attrs: attributes,
                            },
                        ])
                        .run();
                },
        };
    },

    addProseMirrorPlugins() {
        const extensionName = this.name;
        const onObjectClick = this.options.onObjectClick;

        return [
            new Plugin({
                key: new PluginKey('handleClickObject'),
                props: {
                    handleClick: getClickHandler({ extensionName, onObjectClick }),
                },
            }),
        ];
    },
});

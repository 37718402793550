import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import style from './ActionsAudit.scss';
import { setAuditFilteredUsers } from '../../../actions/audit.actions';
import { useDispatch, useSelector } from 'react-redux';
import { ActionAuditSelectors } from '../../../selectors/actionAudit.selector';
import messages from './audit.messages';
import { Select } from '../../UIKit/components/Select/Select.component';
import { PrincipalDescriptor } from '@/serverapi/api';
import { PrincipalsSelectors } from '@/selectors/principals.selectors';

export const UserDataFilter = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const userList: PrincipalDescriptor[] | undefined = useSelector(PrincipalsSelectors.getUsers);

    const filteredUsers: number[] = useSelector(ActionAuditSelectors.filteredUsers);

    const onChange = (users: string[]) => dispatch(setAuditFilteredUsers(users.map(user => Number(user))));

    useEffect(() => {
        return () => {
            dispatch(setAuditFilteredUsers([]));
        };
    }, []);

    return (
        <div className={style.dropdownUserFilterContainer} data-test="security_audit_user-filtration">
            <Select
                value={intl.formatMessage(messages.byUser)}
                showSearch
                isMultiSelect
                onChange={onChange}
                dropdownClassName={style.ulContainerUser}
                dropdownWidth={400}
                allowSelectAll
            >
                {userList.map((user: PrincipalDescriptor) => {
                    const name = [user.lastname, user.name, user.middlename].filter(n => n).join(" ");
                    const label: string = [user.login, name].join(", ");

                    return (
                        <Select.Option
                            key={String(user.id)}
                            checked={filteredUsers?.includes(user.id)}
                            value={String(user.id)}
                            label={label}
                            onChange={onChange}
                        />
                    )})}
            </Select>
        </div>
    );
};

import { Extension } from '@tiptap/core';

type TLineHeightOptions = {
    types: string[];
    defaultLineHeight?: string;
};

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        lineHeight: {
            /**
             * Set the line height attribute
             */
            setLineHeight: (height: string) => ReturnType;
            /**
             * Unset the line height attribute
             */
            unsetLineHeight: () => ReturnType;
        };
    }
}

export const LineHeight = Extension.create<TLineHeightOptions>({
    name: 'lineHeight',

    addOptions() {
        return {
            // TODO другие типы
            types: ['paragraph'],
        };
    },

    addGlobalAttributes() {
        return [
            {
                types: this.options.types,
                attributes: {
                    lineHeight: {
                        default: this.options.defaultLineHeight,
                        parseHTML: (element) => element.style.lineHeight || this.options.defaultLineHeight,
                        renderHTML: (attributes) => {
                            if (!attributes['lineHeight']) {
                                return {};
                            }

                            return { style: `line-height: ${attributes['lineHeight']}` };
                        },
                    },
                },
            },
        ];
    },

    addCommands() {
        return {
            setLineHeight:
                (lineHeight) =>
                ({ commands }) => {
                    return this.options.types.every((type) =>
                        commands.updateAttributes(type, { lineHeight: lineHeight }),
                    );
                },
            unsetLineHeight:
                () =>
                ({ commands }) => {
                    return this.options.types.every((type) => commands.resetAttributes(type, 'lineHeight'));
                },
        };
    },
});

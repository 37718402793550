import { EditorMode } from '../../../models/editorMode';
import { MatrixData, MatrixNode, NodeId } from '../../../serverapi/api';
import {
    CHANGE_MATRIX_NODE_PROPERTIES,
    MATRIX_CREATE,
    MATRIX_CREATE_SUCCESS,
    MATRIX_OPEN,
    MATRIX_OPEN_BY_NODE_ID,
    MATRIX_REQUEST_SUCCESS,
    MATRIX_SAVE_FROM_STORE_REQUEST,
    MATRIX_SAVE_REQUEST,
    MATRIX_SAVE_REQUEST_FAILURE,
    MATRIX_SAVE_REQUEST_SUCCESS,
    REFRESH_MATRIX,
    UPDATE_MATRIX_DATA,
} from '../actionTypes/matrix.actionTypes';
import {
    TMatrixDefaultAction,
    TMatrixDefaultRequestAction,
    TMatrixDefaultRequestFailureAction,
    TMatrixOpenAction,
    TMatrixOpenByNodeIdAction,
    TMatrixNodeChangePropertiesAction,
    TMatrixUpdateDataAction,
    TMatrixSaveFromStoreRequestAction,
    TRefreshMatrixAction,
} from './matrix.actions.types';

export const matrixOpen = (matrix: MatrixNode, mode?: EditorMode): TMatrixOpenAction => ({
    type: MATRIX_OPEN,
    payload: {
        matrix,
        mode,
    },
});

export const matrixOpenByNodeId = (nodeId: NodeId): TMatrixOpenByNodeIdAction => ({
    type: MATRIX_OPEN_BY_NODE_ID,
    payload: { nodeId },
});

export const matrixCreate = (matrix: MatrixNode): TMatrixDefaultAction => ({
    type: MATRIX_CREATE,
    payload: {
        matrix,
    },
});

export const matrixCreateSuccess = (matrix: MatrixNode): TMatrixDefaultAction => ({
    type: MATRIX_CREATE_SUCCESS,
    payload: {
        matrix,
    },
});

export const matrixSaveRequest = (matrix: MatrixNode): TMatrixDefaultRequestAction => ({
    type: MATRIX_SAVE_REQUEST,
    payload: {
        matrix,
    },
});

export const matrixSaveFromStoreRequest = (nodeId: NodeId): TMatrixSaveFromStoreRequestAction => ({
    type: MATRIX_SAVE_FROM_STORE_REQUEST,
    payload: {
        nodeId,
    },
});

export const matrixSaveRequestSuccess = (matrix: MatrixNode): TMatrixDefaultRequestAction => ({
    type: MATRIX_SAVE_REQUEST_SUCCESS,
    payload: {
        matrix,
    },
});

export const changeMatrixNodeProperties = (matrix: MatrixNode): TMatrixNodeChangePropertiesAction => ({
    type: CHANGE_MATRIX_NODE_PROPERTIES,
    payload: {
        matrix,
    },
});

export const updateMatrixData = (nodeId: NodeId, data?: MatrixData, isUndoRedo?: boolean): TMatrixUpdateDataAction => ({
    type: UPDATE_MATRIX_DATA,
    payload: {
        nodeId,
        data,
        isUndoRedo,
    },
});

export const matrixSaveRequestFailure = (serverId: string): TMatrixDefaultRequestFailureAction => ({
    type: MATRIX_SAVE_REQUEST_FAILURE,
    payload: { serverId },
});

export const matrixRequestSuccess = (matrix: MatrixNode): TMatrixDefaultRequestAction => ({
    type: MATRIX_REQUEST_SUCCESS,
    payload: {
        matrix,
    },
});

export const refreshMatrix = (nodeId: NodeId): TRefreshMatrixAction => ({
    type: REFRESH_MATRIX,
    payload: {
        nodeId,
    },
});

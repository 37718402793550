import { ISearchRuleWithValueId } from '@/modules/Search/AttributeFilter/AttributeFilter.types';

import {  GET_AIP_SEARCH_RESULT, SET_AIP_SEARCH_DATA } from '../actionsTypes/assistantInterface.actionTypes';
import { TGetSearchResultAction, TSetAipSearchDataAction, TSearchDataPayload } from './assiastantInterface.actions.types';
import { NodeId, SearchRequestNodeTypesEnum, SearchRequestSearchVisibilityEnum } from '@/serverapi/api';

export const getSearchResult = (
    searchText: string,
    searchRules: ISearchRuleWithValueId[],
    searchVisibility: SearchRequestSearchVisibilityEnum,
    searchNodeTypes: SearchRequestNodeTypesEnum[],
    nodeId: NodeId,
): TGetSearchResultAction => ({
    type: GET_AIP_SEARCH_RESULT,
    payload: { searchText, searchRules, searchVisibility, searchNodeTypes, nodeId },
});

export const setAipSearchData = (payload: TSearchDataPayload): TSetAipSearchDataAction => ({
    payload,
    type: SET_AIP_SEARCH_DATA,
});

import variables from '../styles/variables.scss';

export const paragraphStyleOptions = [
    {
        value: 'P',
        label: 'paragraph',
        style: {
            color: variables.pColor,
            fontWeight: variables.pFontWeight,
            fontSize: variables.pFontSize,
        },
    },
    {
        value: 'H1',
        label: 'heading1',
        style: {
            color: variables.h1Color,
            fontWeight: variables.h1FontWeight,
            fontSize: variables.h1FontSize,
        },
    },
    {
        value: 'H2',
        label: 'heading2',
        style: {
            color: variables.h2Color,
            fontWeight: variables.h2FontWeight,
            fontSize: variables.h2FontSize,
        },
    },
    {
        value: 'H3',
        label: 'heading3',
        style: {
            color: variables.h3Color,
            fontWeight: variables.h3FontWeight,
            fontSize: variables.h3FontSize,
        },
    },
    {
        value: 'H4',
        label: 'heading4',
        style: {
            color: variables.h4Color,
            fontWeight: variables.h4FontWeight,
            fontSize: variables.h4FontSize,
        },
    },
    {
        value: 'H5',
        label: 'heading5',
        style: {
            color: variables.h5Color,
            fontWeight: variables.h5FontWeight,
            fontSize: variables.h5FontSize,
        },
    },
    {
        value: 'H6',
        label: 'heading6',
        style: {
            color: variables.h6Color,
            fontWeight: variables.h6FontWeight,
            fontSize: variables.h6FontSize,
        },
    },
];

export const fontFamilyOptions = [
    {
        value: 'Arial',
        label: 'Arial',
        style: {
            fontFamily: 'Arial, Helvetica, sans-serif',
        },
    },
    {
        value: 'Verdana',
        label: 'Verdana',
        style: {
            fontFamily: 'Verdana, Geneva, sans-serif',
        },
    },
    {
        value: 'Times New Roman',
        label: 'Times New Roman',
        style: {
            fontFamily: ' TimesNewRoman,"Times New Roman", Times, Baskerville, Georgia, serif',
        },
    },
    {
        value: 'Garamond',
        label: 'Garamond',
        style: {
            fontFamily: 'Garamond, Baskerville, "Baskerville Old Face", "Hoefler Text", "Times New Roman",serif',
        },
    },
    {
        value: 'Comic Sans MS',
        label: 'Comic Sans MS',
        style: {
            fontFamily: '"Comic Sans MS", "Comic Sans", cursive',
        },
    },
    {
        value: 'Courier New',
        label: 'Courier New',
        style: {
            fontFamily: '"Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace',
        },
    },
    {
        value: 'Georgia',
        label: 'Georgia',
        style: {
            fontFamily: 'Georgia, Times, "Times New Roman", serif',
        },
    },
    {
        value: 'Lucida Console',
        label: 'Lucida Console',
        style: {
            fontFamily: '"Lucida Console", "Lucida Sans Typewriter", monaco, "Bitstream Vera Sans Mono", monospace',
        },
    },
    {
        value: 'Tahoma',
        label: 'Tahoma',
        style: {
            fontFamily: 'Tahoma, Verdana, Segoe, sans-serif',
        },
    },
];

export const fontSizeOptions = [
    {
        value: '8',
        label: '8',
    },
    {
        value: '9',
        label: '9',
    },
    {
        value: '10',
        label: '10',
    },
    {
        value: '11',
        label: '11',
    },
    {
        value: '12',
        label: '12',
    },
    {
        value: '14',
        label: '14',
    },
    {
        value: '16',
        label: '16',
    },
    {
        value: '18',
        label: '18',
    },
    {
        value: '20',
        label: '20',
    },
    {
        value: '22',
        label: '22',
    },
    {
        value: '24',
        label: '24',
    },
    {
        value: '26',
        label: '26',
    },
    {
        value: '28',
        label: '28',
    },
    {
        value: '36',
        label: '36',
    },
    {
        value: '48',
        label: '48',
    },
    {
        value: '72',
        label: '72',
    },
];

export const lineHeightOptions = [
    {
        value: '1',
        label: '1',
    },
    {
        value: '1.2',
        label: '1.2',
    },
    {
        value: '1.5',
        label: '1.5',
    },
    {
        value: '1.75',
        label: '1.75',
    },
    {
        value: '2',
        label: '2',
    },
    {
        value: '3',
        label: '3',
    },
    {
        value: '3.5',
        label: '3.5',
    },
    {
        value: '4',
        label: '4',
    },
    {
        value: '4.5',
        label: '4.5',
    },
    {
        value: '5',
        label: '5',
    },
    {
        value: '5.5',
        label: '5.5',
    },
    {
        value: '6',
        label: '6',
    },
];

export const TOOLTIP_ENTER_DELAY = 0.2;

export const TOOLTIP_LEAVE_DELAY = 0;

export const APP_PREFIX = 'bpm://';
